import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Grid,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useSelector } from 'react-redux';
import theme from '../theme.js';
import { CmpButton } from '../commonComponents/Toobox.js';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../components/pages.css';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { insertTaskAPI } from '../Action.jsx';
import { fAlertToast } from '../Utility/Utility.jsx';
import CustomerSearchModal from './CustomerSearchModal.jsx';

const TaskModal = ({ open, handleClose }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const xMediaQuery = useMediaQuery('(max-width:1250px)');
  const depts = useSelector((state) => state.user.depts);
  const users = useSelector((state) => state.user.users);
  const location = useSelector((state) => state.user.LocationDetail);
  const projects = useSelector((state) => state.user.project);
  const typTasks = ["Payment FollowUp", "AMC FollowUp","Software Enhancement","Software Quotation","Bugs Fixing", "New Features", "Report Development", "Support"]
  const vLoginData = useSelector((state) => state.user);
  const [isCustomerSearchModalOpen, setIsCustomerSearchModalOpen] = useState(false);

  const taskObj = {
    T_ID: 0,
    title: '',
    dueDate: new Date(),
    priority: 'Low',
    typofTask:'Payment FollowUp',
    module:'',
    department: depts?.length > 0 ? depts[0]?.UId : '',
    assignee: users?.length > 0 ? users[0]?.UId : '',
    supervisor: users?.length > 0 ? users[0]?.UId : '',
    teamLead: users?.length > 0 ? users[0]?.UId : '',
    project: projects?.length > 0 ? projects[0]?.UId : '',
    location: location?.length > 0 ? location[0]?.Uid : '',
    customerCode: '',
    noOfDays:0,
    customerName: '',
    contactPerson: '',
    contactNo: '',
    contactEmail: '',
    informCustomer: 'N',
    comments: ''
  };

  const [formData, setFormData] = useState(taskObj);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'customerName' && value === '') {
      setFormData((prevState) => ({
        ...prevState,
        customerCode: '',
        contactPerson: '',
        contactNo: '',
        contactEmail: '',
        [name]: value
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date ? dayjs(date).format('YYYY-MM-DD') : ''
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (checked ? 'Y' : 'N') : value
    }));
  };

  const handleAddTask = async () => {
    const payload = {
      T_Title: formData.title,
      T_Desc: formData.comments,
      T_DueDate: formData.dueDate,
      T_Priority: formData.priority,
      T_Customer: formData.customerCode,
      T_ContactPerson: formData.contactPerson,
      T_ContactNo: formData.contactNo,
      T_Email: formData.contactEmail,
      T_Dept: formData.department,
      T_Assignee: formData.assignee,
      T_Supervisor: formData.supervisor,
      T_TeamLead: formData.teamLead,
      T_EnBy: vLoginData?.userCd,
      T_InformCust: formData.informCustomer,
      T_Project: formData.project,
      T_ID: formData.T_ID,
      T_Loc: formData.location,
      T_FlexiDays:formData.noOfDays,
      T_Type:formData.typofTask,
      T_ModuleDetail:formData.module,
    };
    const result = await insertTaskAPI(payload);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Task Sucessfully Added');
      handleModalClose();
    } else {
      fAlertToast('FAILED', "Task doesn't Added");
    }
  };

  const handleModalClose = () => {
    setFormData(taskObj);
    handleClose();
  };

  const handleSearchCustomer = (e) => {
    if (e.key === 'Enter' || e._reactName === 'onClick') {
      setIsCustomerSearchModalOpen(true);
    }

    setTimeout(() => {
      document.getElementById('accountName')?.focus();
    }, 500);
  };

  const searchCustomerModalClose = () => {
    setIsCustomerSearchModalOpen(false);

    setTimeout(() => {
      document.getElementById('customerName')?.focus();
    }, 500);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: vMediaQuery ? '80vh' : xMediaQuery? '92vh' :'84vh',
    overflowY: 'auto',
    width: vMediaQuery ? 350 : 600,
    bgcolor: '#fff',
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`
  };

  return (
    <Modal open={open} onClose={handleModalClose} className="modal">
      <Box sx={style}>
        <div>
          <div className="add-task">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={theme.palette.primary.main}
              justifyContent={'flex-start'}>
              Add Task
            </Typography>

            <CloseIcon onClick={handleModalClose} sx={{ cursor: 'pointer', marginTop: '4px' }} />
          </div>
          <Grid
            className="task-detail"
            container
            spacing={isMobile ? 1 : 2}
            sx={{ paddingY: 2, paddingX: isMobile ? 3 : 4 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                id="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"
                  value={formData.dueDate}
                  inputFormat="DD/MM/YYYY"
                  name="dueDate"
                  onChange={(date) => handleDateChange(date, 'dueDate')}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth id="dueDate" className="duedate" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Extended Day"
                name="noOfDays"
                id="noOfDays"
                type='number'
                value={formData.noOfDays}
                onChange={handleChange}
                InputProps={{
                  inputProps: {
                    style: {
                      MozAppearance: "textfield",
                    },
                  },
                  sx: {
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Priority</InputLabel>
                <Select
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  label="Priority">
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Critical">Critical</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Types of Task</InputLabel>
                <Select
                  name="typofTask"
                  value={formData.typofTask}
                  onChange={handleChange}
                  label="Types of Task">
                  {typTasks.map((typ) => (
                    <MenuItem key={typ} value={typ}>
                      {typ}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  label="Department">
                  {depts.map((dept) => (
                    <MenuItem key={dept.UId} value={dept.UId}>
                      {dept.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextField
                fullWidth
                label="Module"
                name="module"
                id="module"
                value={formData.module}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Assignee</InputLabel>
                <Select
                  name="assignee"
                  value={formData.assignee}
                  onChange={handleChange}
                  label="Assignee">
                  {users.map((user) => (
                    <MenuItem key={user.UId} value={user.UId}>
                      {user.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Supervisor</InputLabel>
                <Select
                  name="supervisor"
                  value={formData.supervisor}
                  onChange={handleChange}
                  label="Supervisor">
                  {users.map((user) => (
                    <MenuItem key={user.UId} value={user.UId}>
                      {user.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Team Lead</InputLabel>
                <Select
                  name="teamLead"
                  value={formData.teamLead}
                  onChange={handleChange}
                  label="Team Lead">
                  {users.map((user) => (
                    <MenuItem key={user.UId} value={user.UId}>
                      {user.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Project</InputLabel>
                <Select
                  name="project"
                  value={formData.project}
                  onChange={handleChange}
                  label="Project">
                  {projects.map((project) => (
                    <MenuItem key={project.UId} value={project.UId}>
                      {project.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Customer Code"
                name="customerCode"
                value={formData.customerCode}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                label="Customer Name"
                name="customerName"
                id="customerName"
                value={formData.customerName}
                onChange={handleChange}
                onKeyDown={handleSearchCustomer}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearchCustomer}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact Person"
                name="contactPerson"
                value={formData.contactPerson}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact No"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact E-Mail"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={7.1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="informCustomer"
                    checked={formData.informCustomer === 'Y'}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Inform Customer once work is done"
              />
            </Grid>
            <Grid item xs={12} sm={4.9}>
              <FormControl fullWidth>
                <InputLabel>Location</InputLabel>
                <Select
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  label="Team Lead">
                  {location?.map((user) => (
                    <MenuItem key={user.Uid} value={user.Uid}>
                      {user.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                multiline
                rows={3.5}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CmpButton xbtnName={'Save'} xOnClick={handleAddTask} />
            </Grid>
          </Grid>
        </div>
        <CustomerSearchModal
          open={isCustomerSearchModalOpen}
          handleClose={searchCustomerModalClose}
          handleSearch={handleSearchCustomer}
          addCustomerName={formData.customerName}
          setFormData={setFormData}
          vMediaQuery={vMediaQuery}
        />
      </Box>
    </Modal>
  );
};

export default TaskModal;
