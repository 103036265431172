import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  InputAdornment,
  IconButton
} from '@mui/material';
import theme from '../theme.js';
import CloseIcon from '@mui/icons-material/Close';
import '../components/pages.css';
import { CmpTypography } from '../commonComponents/Toobox.js';
import { searchTaskCustomerAPI } from '../Action.jsx';
import SearchIcon from '@mui/icons-material/Search';

const txtInputStyle = {
  '& .MuiInputBase-input': {
    padding: '6px 10px !important'
  },
  '& .MuiFormLabel-root': {
    top: '0px !important',
    fontSize: '14px !important'
  },
  width: '100%'
};

const CustomerSearchModal = ({
  open,
  handleClose,
  addCustomerName,
  setFormData,
  updateCustomerName,
  setSelectedTask,
  vMediaQuery
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [accountCode, setAccountCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [customerData, setCustomerData] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
  const tableRef = useRef(null);

  useEffect(() => {
    setAccountName(addCustomerName || updateCustomerName);
  }, [addCustomerName, updateCustomerName]);

  useEffect(() => {
    if (open && accountName?.length > 3) {
      fetchCustomerData();
    } else {
      setCustomerData([]);
      setAccountCode('');
    }
  }, [open]);

  const fetchCustomerData = async () => {
    const CusObj = {
      v_AcCd: accountCode,
      v_Name: accountName
    };
    const result = await searchTaskCustomerAPI(CusObj);

    if (result.status === 200) {
      setCustomerData(result.data);
      setFocusedRowIndex(-1);
    }
  };

  const handleAccountCodeChange = (e) => {
    setAccountCode(e.target.value);
  };

  const handleAccountNameChange = (e) => {
    setAccountName(e.target.value);

    if (e.target.value === '') {
      setCustomerData([]);
    }
  };

  const fSearch = () => {
    if (accountName.length > 3 || accountCode) {
      fetchCustomerData();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' && customerData.length > 0) {
      setFocusedRowIndex((prevIndex) => {
        const nextIndex = prevIndex + 1 < customerData.length ? prevIndex + 1 : 0;
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp' && customerData.length > 0) {
      setFocusedRowIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : customerData.length - 1;
        return nextIndex;
      });
    } else if (e.key === 'Enter' && focusedRowIndex !== -1) {
      handleRowClick(customerData[focusedRowIndex]);
    } else if (e.key === 'Enter') {
      if (accountName.length > 3 || accountCode) {
        fetchCustomerData();
      }
    }
  };

  useEffect(() => {
    if (focusedRowIndex !== -1 && tableRef.current) {
      const row = tableRef.current.querySelectorAll('tr')[focusedRowIndex];
      if (row) {
        row.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [focusedRowIndex]);

  const handleRowClick = (row) => {
    if (updateCustomerName) {
      setSelectedTask((prevOrder) => ({
        ...prevOrder,
        AcCd: row.AcCd,
        CustomerName: row.Name2,
        T_ContactPerson: row.Contact,
        T_ContactNo: row.Mobile,
        T_Email: row.EMail
      }));
      handleClose();
    } else {
      setFormData((prevOrder) => ({
        ...prevOrder,
        customerCode: row.AcCd,
        customerName: row.Name2,
        contactPerson: row.Contact,
        contactNo: row.Mobile,
        contactEmail: row.EMail
      }));
      handleClose();
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:vMediaQuery ? 335: 550,
    bgcolor: '#fff',
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`
  };

  return (
    <Modal open={open} onClose={handleClose} className='modal'>
      <Box sx={style}>
        <div className="modal-head">
          <Typography variant="subtilte1" sx={{ margin: 0 }}>
            Customer Search
          </Typography>

          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </div>
        <Grid container spacing={isMobile ? 1 : 2} sx={{ paddingX:vMediaQuery?2: 6, paddingY:vMediaQuery?1: 2 }}>
          <Grid item xs={3}>
            <CmpTypography xText={'Account Name'} />
          </Grid>
          <Grid item xs={1}>
            <CmpTypography xText={':'} />
          </Grid>
          <Grid item xs={8}>
            <TextField
              sx={txtInputStyle}
              fullWidth
              name="accountName"
              id="accountName"
              value={accountName}
              onChange={handleAccountNameChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={fSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CmpTypography xText={'Account Code'} />
          </Grid>
          <Grid item xs={1}>
            <CmpTypography xText={':'} />
          </Grid>
          <Grid item xs={8}>
            <TextField
              sx={txtInputStyle}
              fullWidth
              name="accountCode"
              id="accountCode"
              value={accountCode}
              onChange={handleAccountCodeChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={fSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ paddingX:vMediaQuery? 2: '20px', mb: 2 }}>
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                height: '40vh',
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: theme.palette.shadow.main
              }}>
              <Table
                ref={tableRef}
                size={isMobile ? 'small' : 'medium'}
                sx={{
                  '& .MuiTableCell-root': {
                    borderRight: theme.palette.tblColBorder,
                    textAlign: 'left'
                  }
                }}
                stickyHeader>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.text,
                        whiteSpace: 'nowrap',
                        paddingX: '10px !important',
                        paddingY: '5px !important'
                      }
                    }}>
                    <TableCell>AcCd</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>MobileNo</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerData.map((row, index) => (
                    <TableRow
                      key={row.AcCd}
                      onClick={() => handleRowClick(row, index)}
                      sx={{
                        '& .MuiTableCell-root': {
                          paddingY: '4px !important',
                          paddingX: '10px !important',
                          whiteSpace: 'nowrap',
                          cursor: 'pointer',
                          backgroundColor: focusedRowIndex === index ? '#f0f0f0' : 'transparent'
                        }
                      }}>
                      <TableCell>{row.AcCd}</TableCell>
                      <TableCell>{row.Name2}</TableCell>
                      <TableCell>{row.Contact}</TableCell>
                      <TableCell>{row.Mobile}</TableCell>
                      <TableCell>{row.Phone}</TableCell>
                      <TableCell>{row.EMail}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CustomerSearchModal;
