import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  
  palette: {
    primary: {
      surface:"#eceff8",
      main: '#1976d2',
      text:'#ffffff',
      font:`'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
    },
    secondary: {
      main: '#9c27b0',
      text:'#323338',
      tableBorderClr:'#d0d4e4'
    },
    statusClr:{
      critical:'#414246',
      high:'#371097eb',
      medium:'#5138d5e8',
      low:'#4b92f8',
      green:'#00c875',
      yellow:'#fecd02',
      orange:'#fdab3d',
      red:'#df2f4a',
      open:'#1bb4e4',
      progress:'#f88e56',
      qualify:'#a2ac00',
      verified:'#57a601',
      closed:'#5e4b4b',
      deleted:'#b80000',
      completed:'#3d7500',
      notComp:'#c4c4c4'
    },
    background: {
      page:'#ffffff',
      drawer:'#fcfbfb'
    },
    shadow:{
      main:'0px 3px 6px rgba(33, 150, 243, 0.3)',
      borderClr: '2px solid #e3dddda8',
      flash: '1px solid #e3dddda8',
      border:'2px solid #d5daff',
    },
  //  tblColBorder:'1px solid rgba(224, 224, 224, 1)',
   tblColBorder:'1px solid #d0d4e4'
  },
});

export default theme;

