import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../Layout';
import Home from '../Home';
import MyWork from '../MyWork';
import Login from '../Login';
import theme from '../../theme.js';
import ActivityNotification from '../ActivityNotification';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import Project from '../project/Project.jsx';

const ProjectRoutes = () => {
  const user = useSelector((state) => state.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/home" element={<Home />} />
        <Route path="/myWork" element={<MyWork />} />
        <Route path="/project" element={<Project />} />
        {isMobile && <Route path="/todayActivity" element={<ActivityNotification user={user} />} />}
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default ProjectRoutes;
