import {
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../theme.js';
import { todayActivityAPI } from '../Action.jsx';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment-timezone';

const ActivityNotification = ({ user }) => {
  const [nAcitivity, setNActivity] = useState([]);
  const [superName, setSuperName] = useState([]);
  const [nameCounts, setNameCounts] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [expandedDes, setExpandedDes] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const notification = async () => {
    const obj = {
      User_id: user?.userCd
    };
    const result = await todayActivityAPI(obj);

    if (result.status === 200) {
      if (result?.data?.length > 0) {
        let GridID = result?.data?.map((obj, i) => ({ ...obj, id: i + 1 }));
        setNActivity(GridID);
      } else {
        setNActivity([]);
      }

      // Distinguish unique supervisor
      const uniqueUsers = [...new Set(result.data.map((item) => item.userName))];
      setSuperName(uniqueUsers);

      const counts = result.data.reduce((acc, item) => {
        acc[item.userName] = (acc[item.userName] || 0) + 1;
        return acc;
      }, {});
      setNameCounts(counts);
    }
  };

  const handleChipClick = (username) => {
    if (selectedNames.includes(username)) {
      setSelectedNames(selectedNames.filter((u) => u !== username));
    } else {
      setSelectedNames([...selectedNames, username]);
    }
  };

  const handleShowActivMore = (index) => {
    setExpandedDes(expandedDes === index ? null : index);
  };

  const filteredActivities = nAcitivity.filter(
    (activity) => selectedNames.length === 0 || selectedNames.includes(activity.userName)
  );

  useEffect(() => {
    notification();
  }, [user?.userCd]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingX: '10px',
        height: isMobile ? '86vh !important' : '',
        overflowY: isMobile ? 'auto' : ''
      }}>
      <Typography variant="h6" sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
        Today Activity
      </Typography>
      {/* Chips for Usernames */}
      <Box sx={{ marginY: 1 }}>
        <Grid container spacing={1} className="badgeClass">
          {superName.map((username, index) => (
            <Grid item key={index} sx={{ '&.MuiGrid-item': { paddingTop: '4px !important' } }}>
              <Badge badgeContent={nameCounts[username] || 0} overlap="circular">
                <Chip
                  label={username}
                  color={selectedNames.includes(username) ? 'secondary' : 'primary'}
                  onClick={() => handleChipClick(username)}
                />
              </Badge>
            </Grid>
          ))}
        </Grid>
      </Box>

      {filteredActivities?.map((activity, index) => (
        <Card
          className="card"
          key={index}
          sx={{
            mb: 1,
            backgroundColor: 'background.drawer',
            border: ` ${theme.palette.shadow.borderClr} !important`,
            borderRadius: '10px'
          }}>
          <CardContent>
            <Box sx={{ marginBottom: 2 }}>
              {/* Task Description*/}
              <Typography
                variant="body2"
                sx={{
                  fontSize: '13px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp:
                    expandedDes === index || activity.TaskDesc.length <= 80 ? 'none' : 3, // Show full text if expanded
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace:
                    expandedDes === index || activity.TaskDesc.length <= 80 ? 'normal' : 'wrap'
                }}>
                {activity.TaskDesc}
              </Typography>

              {/* Activity Description */}
              <Typography
                className="notydesc"
                variant="body2"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp:
                    expandedDes === index || activity.ActivityDesc.length <= 120 ? 'none' : 3, // Show full text if expanded
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace:
                    expandedDes === index || activity.ActivityDesc.length <= 120 ? 'normal' : 'wrap'
                }}>
                {activity.ActivityDesc}
              </Typography>

              {/* Show more/less button */}
              <div>
                {(activity.ActivityDesc.length > 120 || activity.TaskDesc.length > 80) && (
                  <Tooltip title={expandedDes === index ? 'Show less' : 'Show more'}>
                    <IconButton onClick={() => handleShowActivMore(index)}>
                      {expandedDes === index ? <ExpandLessIcon /> : <MoreHorizIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div>
                <Typography variant="body2" className="cus-name">
                  {' '}
                  {activity.customerName}
                </Typography>
              </div>
              <strong className="user-name"> {activity.userName}</strong>
              <div className="aligntext">
                <Typography className="descriptDate" variant="body2">
                  {moment(activity.ActivityDateTime).format('DD/MM/YYYY')}{' '}
                  {moment(activity.ActivityDateTime).format('hh:mm A')}
                </Typography>
                <Typography className="type" color={theme.palette.statusClr.low}>
                  {activity.supervisorName}
                </Typography>
              </div>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ActivityNotification;
