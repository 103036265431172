import React, { useEffect } from 'react';
import { Box, CircularProgress, CssBaseline, Grid, ThemeProvider } from '@mui/material';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useNavigate, useLocation, BrowserRouter } from 'react-router-dom';
import { persistor, store } from './Slice/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login';
import Header from './components/Header';
import ProjectRoutes from './components/router/index.js';
import theme from './theme.js';

function AppContent() {
  const vLoginData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (vLoginData?.userCd) {
      if (location.pathname === '/home' || location.pathname === '/') {
        return;
      } else if (location.pathname === '/project') {
        // If user is trying to access todayActivity
        return;
      } else if (location.pathname === '/todayActivity') {
        // If user is trying to access todayActivity
        return;
      }
      navigate('/myWork');
    } else {
      navigate('/'); // Redirect to login page if not logged in
    }
  }, [vLoginData, location.pathname, navigate]);

  return (
    // <SocketProvider vLoginData={vLoginData} >
    <Box
      backgroundColor={theme.palette.primary.surface}
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      {vLoginData?.userCd ? (
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <ProjectRoutes />
          </Grid>
        </Grid>
      ) : (
        <Login />
      )}
    </Box>
    // </SocketProvider>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <div id="loader" style={{ display: 'none' }} className="mui-spinner">
              <CircularProgress
                size={60}
                sx={{
                  animationDuration: '500ms'
                }}
              />
            </div>
            <AppContent />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
