import React, { useState } from 'react';
import { Box, Modal, Typography, Grid, TextField, useMediaQuery } from '@mui/material';
import { CmpButton } from '../commonComponents/Toobox';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../theme.js';
import moment from 'moment';
import { insertTaskActivityAPI } from '../Action';
import { fAlertToast } from '../Utility/Utility.jsx';
import { useDispatch } from 'react-redux';
import { setRefeshApiCall } from '../Slice/userSlice.js';

const SupervisingDetailTask = ({
  open,
  handleClose,
  task,
  setSelectedTask,
  user,
  setIsDrawerDataChange,
  apiCallCounts
}) => {
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const dispatch = useDispatch();
  const [narration, setNaration] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    overflowY: 'auto',
    width: vMediaQuery ? 320 : 600,
    bgcolor: '#fff',
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`
  };

  const fClose = async () => {
    const payload = {
      A_T_ID: task?.T_ID,
      A_T_Type: 'Closed',
      A_T_Desc: narration,
      A_T_EnBy: user?.userCd,
      A_T_NextFollowup: '01/01/1900'
    };

    const response = await insertTaskActivityAPI(payload);
    if (response.status === 200) {
      fAlertToast('SUCCESS', 'Task Closed successfully');
      setNaration('');
      // Increment only myWorkRefreshCount
      dispatch(
        setRefeshApiCall({
          ...user.refreshApiCall,
          mySupervisingRefreshCount: apiCallCounts?.mySupervisingRefreshCount + 1
        })
      );
      handleClose();

      setIsDrawerDataChange(true);
    } else {
      fAlertToast('FAILED', `'Task doesn't Closed'`);
    }
  };

  const fReOpen = async () => {
    const payload = {
      A_T_ID: task?.T_ID,
      A_T_Type: 'Re-Open',
      A_T_Desc: narration,
      A_T_EnBy: user?.userCd,
      A_T_NextFollowup: moment(new Date()).format('MM/DD/YYYY')
    };
    const response = await insertTaskActivityAPI(payload);
    if (response.status === 200) {
      fAlertToast('SUCCESS', 'Task Re-Opened successfully');
      setNaration('');
      // Increment myWorkRefreshCount and mySupervisingRefreshCount
      dispatch(
        setRefeshApiCall({
          ...user.refreshApiCall,
          mySupervisingRefreshCount: apiCallCounts?.mySupervisingRefreshCount + 1,
          myWorkRefreshCount: apiCallCounts?.myWorkRefreshCount + 1
        })
      );
      handleClose();
      setIsDrawerDataChange(true);
    } else {
      fAlertToast('FAILED', 'Task could not be Re-Opened');
    }
  };

  const handleModalClose = () => {
    setSelectedTask([]);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose} className="modal">
      <Box sx={style}>
        <div className="modal-head">
          <Typography variant="subtilte1" sx={{ margin: 0 }}>
            Supervising Task Details
          </Typography>

          <CloseIcon onClick={handleModalClose} sx={{ cursor: 'pointer' }} />
        </div>
        <Grid
          className="detail-drawer"
          container
          spacing={1}
          sx={{ paddingY: vMediaQuery ? 2 : 3, paddingX: vMediaQuery ? 3 : 4 }}>
          <Grid item xs={3.2} sm={3.5} md={3.6}>
            <Typography variant="body2">Task ID</Typography>
          </Grid>
          <Grid item xs={0.4} sm={0.5} md={0.4}>
            <Typography variant="body2">:</Typography>
          </Grid>
          <Grid item xs={8.4} sm={8} md={8}>
            <TextField
              fullWidth
              variant="standard"
              value={task?.T_ID || ''}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          {/* Title */}
          <Grid item xs={3.2} sm={3.5} md={3.6}>
            <Typography variant="body2">Title</Typography>
          </Grid>
          <Grid item xs={0.4} sm={0.5} md={0.4}>
            <Typography variant="body2">:</Typography>
          </Grid>
          <Grid item xs={8.4} sm={8} md={8}>
            <TextField
              fullWidth
              variant="standard"
              value={task?.T_Title || ''}
              InputProps={{
                readOnly: true
              }}
              multiline
            />
          </Grid>

          {/* Description */}
          <Grid item xs={3.2} sm={3.5} md={3.6}>
            <Typography variant="body2" marginTop={'4px'}>
              Naration by Supervisor
            </Typography>
          </Grid>
          <Grid item xs={0.4} sm={0.5} md={0.4}>
            <Typography variant="body2" marginTop={'4px'}>
              :
            </Typography>
          </Grid>
          <Grid item xs={8.4} sm={8} md={8}>
            <TextField
              fullWidth
              id="naration"
              variant="standard"
              value={narration}
              onChange={(e) => setNaration(e.target.value)}
              // sx={{ '&.MuiTextField-root': { marginY: '16px' } }}
              multiline
            />
          </Grid>

          <Grid item xs={3.2} sm={3.5} md={3.6}>
            <Typography variant="body2" marginTop={'4px'}>
              Recent Status
            </Typography>
          </Grid>
          <Grid item xs={0.4} sm={0.5} md={0.4}>
            <Typography variant="body2" marginTop={'4px'}>
              :
            </Typography>
          </Grid>
          <Grid item xs={8.4} sm={8} md={8}>
            <TextField
              fullWidth
              variant="standard"
              value={task?.RecentStatus || ''}
              InputProps={{
                readOnly: true
              }}
              multiline
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 1 }}>
            <CmpButton xbtnName={'Close'} xOnClick={fClose} />
            <CmpButton xbtnName={'Re-Open'} xOnClick={fReOpen} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SupervisingDetailTask;
