import {
  Box,
  Drawer,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import theme from '../../theme';
import { CmpButton } from '../../commonComponents/Toobox';
import { useSelector } from 'react-redux';
import ProjectDetailsDrawer from './ProjectDetailsDrawer';
import { salesLeadSummaryAPI } from '../../Action';
import moment from 'moment';
import { Description } from '@mui/icons-material';

const Project = () => {
  const xMediaQuery = useMediaQuery('(max-width:600px)');
  const aMediaQuery = useMediaQuery('(max-width:900px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const depts = useSelector((state) => state.user.depts);
  const users = useSelector((state) => state.user.users);
  const projects = useSelector((state) => state.user.project);
  const location = useSelector((state) => state.user.LocationDetail);
  const vLoginData = useSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loadData, setLoadData] = useState({
    fromDate: new Date(),
    toDate: new Date()
  });

  const leadObj = {
    leadID: 0,
    title: '',
    description: '',
    dueDate: new Date(),
    customerCode: '',
    customerName: '',
    customeArea: '',
    contactPerson: '',
    contactNo: '',
    contactPhone: '',
    contactEmail: '',
    leadReference: '',
    customerBusniness: '',
    customerRequire: '',
    marketPrice: 0,
    farePrice: 0,
    bottomPrice: 0,
    modules: '',
    emirates: '',
    department: depts?.length > 0 ? depts[0]?.UId : '',
    assignee: users?.length > 0 ? users[0]?.UId : '',
    supervisor: users?.length > 0 ? users[0]?.UId : '',
    teamLead: users?.length > 0 ? users[0]?.UId : '',
    project: projects?.length > 0 ? projects[0]?.UId : '',
    location: location?.length > 0 ? location[0]?.Uid : '',
    typofTask: 'Payment FollowUp'
  };
  const [selectedTask, setSelectedTask] = useState(null);
  const [projectData, setProjectData] = useState(null);

  const handleDateChange = (date, name) => {
    setLoadData((prevState) => ({
      ...prevState,
      [name]: date ? dayjs(date).format('YYYY-MM-DD') : ''
    }));
  };

  const leadSummary = async () => {
    const obj = {
      FromDate: moment(loadData.fromDate).format('MM/DD/YYYY'),
      ToDate: moment(loadData.toDate).format('MM/DD/YYYY'),
      Status: 'Active'
    };

    const result = await salesLeadSummaryAPI(obj);
    console.log('result', result);
    if (result.status === 200) {
      setProjectData(result.data);
    }
  };
  console.log('projectData',projectData);
  

  const handleOpen = () => {
    setSelectedTask(leadObj);
    setDrawerOpen(true);

    setTimeout(() => {
      document.getElementById('title')?.focus();
    }, 500);
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);

    setDrawerOpen(true);

    // setTimeout(() => {
    //   document.getElementById('activity')?.focus();
    // }, 500);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  // const projectData = [
  //   {
  //     leadID: 1,
  //     title: 'task',
  //     description: 'test the lead',
  //     dueDate: '2024-10-08T00:00:00Z',
  //     customerCode: 'D0862',
  //     customerName: 'Gravity Solutions FZC',
  //     customeArea: 'Dubai',
  //     contactPerson: 'Mr.Ramesh',
  //     contactNo: '055 5648313',
  //     contactPhone: '055 5648313',
  //     contactEmail: 'ramesh@gravity-soft.com',
  //     leadReference: '',
  //     customerBusniness: '',
  //     customerRequire: '',
  //     marketPrice: 100,
  //     farePrice: 55,
  //     bottomPrice: 20,
  //     modules: 'Gravity RP',
  //     emirates: 'Dubai',
  //     department: 'Software',
  //     assignee: 'PREETHI',
  //     supervisor: 'SIVA',
  //     teamLead: 'ADMINISTRATOR',
  //     project: 'Gravity RP',
  //     typofTask: 'Payment FollowUp'
  //   }
  // ];

  useEffect(() => {
    leadSummary();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        // height: '86vh',
        // overflowY: 'auto',
        paddingX: aMediaQuery ? '20px' : 14
      }}>
      <Typography
        variant="h6"
        sx={{ fontSize: xMediaQuery ? '20px' : '30px', color: theme.palette.primary.main }}>
        Project
      </Typography>
      <Grid
        container
        className="task-detail"
        columnSpacing={xMediaQuery ? 0 : 4}
        rowSpacing={xMediaQuery ? 2 : 0}
        sx={{ paddingY: xMediaQuery ? 2 : 0 }}>
        <Grid item xs={12} sm={4} md={4} lg={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={loadData.fromDate}
              inputFormat="DD/MM/YYYY"
              name="fromDate"
              onChange={(date) => handleDateChange(date, 'fromDate')}
              renderInput={(params) => (
                <TextField {...params} fullWidth id="fromDate" className="duedate" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              value={loadData.toDate}
              inputFormat="DD/MM/YYYY"
              name="toDate"
              onChange={(date) => handleDateChange(date, 'toDate')}
              renderInput={(params) => (
                <TextField {...params} fullWidth id="toDate" className="duedate" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={1}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: xMediaQuery ? '' : '4px'
            }}>
            <CmpButton xbtnName={'Load'} xOnClick={leadSummary} />
          </div>
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={1}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: xMediaQuery ? '' : '4px'
            }}>
            <CmpButton xbtnName={'New'} xOnClick={handleOpen} />
          </div>
        </Grid>
      </Grid>
      <div style={{ paddingTop: xMediaQuery ? '' : '30px' }}>
        <TableContainer className="table" component={Paper} sx={{ height: '60vh !important' }}>
          <Table
            size={isMobile ? 'small' : 'medium'}
            sx={{
              '& .MuiTableCell-root': {
                borderRight: theme.palette.tblColBorder,
                textAlign: 'left'
              }
            }}
            stickyHeader>
            <TableHead>
              <TableRow className="table-head">
                {/* <TableCell>Task ID</TableCell> */}
                {/* <TableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: theme.palette.primary.main
                }}>
                Title
              </TableCell> */}
                <TableCell
                  sx={{
                    position: 'sticky'
                    //   left: bMediaQuery ? 0 : '',
                    //   zIndex: bMediaQuery ? 3 : '',
                    //   backgroundColor: `${filteredTasks.Modified === 1 ? '#eef1fd45' : ''}`
                  }}>
                  Lead ID
                </TableCell>
                <TableCell>title</TableCell>
                <TableCell>description</TableCell>
                <TableCell>dueDate</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Customer Area </TableCell>
                <TableCell>Contact Person</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Mobile No</TableCell>
                <TableCell>EMail</TableCell>
                <TableCell>Emirates</TableCell>
                <TableCell>Lead Reference</TableCell>
                <TableCell>Business Nature</TableCell>
                <TableCell>Requirements</TableCell>
                <TableCell>Market Price</TableCell>
                <TableCell>Fare Price</TableCell>
                <TableCell>Bottom Price</TableCell>
                <TableCell>Modules</TableCell>
                <TableCell>Assignee</TableCell>
                <TableCell>Supervisor</TableCell>
                <TableCell>Team Lead</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Project</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectData?.map((row, index) => {
                return (
                  <TableRow
                    className="table-body"
                    key={index}
                    sx={{
                      color: `${row.Modified === 1 ? '#010000' : ''} !important`,
                      '& .MuiTableCell-root': {
                        fontWeight: `${row.Modified === 1 ? 600 : ''} !important`
                      },
                      '&:hover': {
                        backgroundColor: `${row.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`
                      }
                    }}
                    onClick={() => handleTaskClick(row)}>
                    <TableCell>{row.leadID}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.dueDate}</TableCell>
                    <TableCell>{row.customerName}</TableCell>
                    <TableCell>{row.customeArea}</TableCell>
                    <TableCell>{row.contactPerson}</TableCell>
                    <TableCell>{row.contactNo}</TableCell>
                    <TableCell>{row.contactPhone}</TableCell>
                    <TableCell>{row.contactEmail}</TableCell>
                    <TableCell>{row.emirates}</TableCell>
                    <TableCell>{row.leadReference}</TableCell>
                    <TableCell>{row.customerBusniness}</TableCell>
                    <TableCell>{row.customerRequire}</TableCell>
                    <TableCell>{row.marketPrice}</TableCell>
                    <TableCell>{row.farePrice}</TableCell>
                    <TableCell>{row.bottomPrice}</TableCell>
                    <TableCell>{row.modules}</TableCell>
                    <TableCell>{row.assignee}</TableCell>
                    <TableCell>{row.supervisor}</TableCell>
                    <TableCell>{row.teamLead}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>{row.typofTask}</TableCell>
                    <TableCell>{row.project}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Drawer
        className="modal"
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: xMediaQuery ? 300 : 500,
            borderTopLeftRadius: '10px',
            overflowX: 'hidden',
            top: 'auto',
            bottom: 0,
            backgroundColor: theme.palette.background.drawer,
            borderBottom: 'none !important',
            borderRight: 'none !important',
            border: `${theme.palette.shadow.border}`,
            boxShadow: theme.palette.shadow.main,
            height: `calc(100vh - 64px) !important`
          }
        }}
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}>
        <ProjectDetailsDrawer
          xMediaQuery={xMediaQuery}
          vMediaQuery={aMediaQuery}
          handleClose={handleDrawerClose}
          task={selectedTask}
          vLoginData={vLoginData}
          setSelectedTask={setSelectedTask}
        />
      </Drawer>
    </Box>
  );
};

export default Project;
