import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import TaskList from './TaskList';
import TaskModal from './TaskModal';
import theme from '../theme.js';
import SupervisingTask from './SupervisingTask.js';

const MyWork = () => {
  const xMediaQuery = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);

    setTimeout(() => {
      document.getElementById('title')?.focus();
    }, 500);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        height: '86vh',
        overflowY: 'auto',
        paddingBottom: '20px',
        paddingX: xMediaQuery ? '20px' : '36px',
        backgroundColor: xMediaQuery ? theme.palette.background.page : ''
      }}>
      <TaskList handleOpen={handleOpen} />
      <SupervisingTask />
      <TaskModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default MyWork;
