import React, { useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton
} from '@mui/material';
import theme from '../theme.js';
import CloseIcon from '@mui/icons-material/Close';
import '../components/pages.css';
import { CmpButton, CmpButtonError } from '../commonComponents/Toobox.js';
import SearchIcon from '@mui/icons-material/Search';
import { deleteTaskAPI, insertTaskAPI, qualifyTaskAPI } from '../Action.jsx';
import { fAlertToast } from '../Utility/Utility.jsx';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomerSearchModal from './CustomerSearchModal.jsx';

const TaskDetailModal = ({
  open,
  handleClose,
  selectedTask,
  setSelectedTask,
  setIsDrawerDataChange,
  fPendingTaskAccept,
  fPendingTaskQualify,
  searchInputValue,
  setFilteredQualifyTasks,
  pendingQualifiedTasks,
  setPendingQualifiedTasks
}) => {
  const user = useSelector((state) => state.user);
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const xMediaQuery = useMediaQuery('(max-width:1250px)');

  const depts = useSelector((state) => state.user.depts);
  const users = useSelector((state) => state.user.users);
  const location = useSelector((state) => state.user.LocationDetail);
  const projects = useSelector((state) => state.user.project);
  const typTasks = [
    'Payment FollowUp',
    'AMC FollowUp',
    'Software Enhancement',
    'Software Quotation',
    'Bugs Fixing',
    'New Features',
    'Report Development',
    'Support'
  ];
  const vLoginData = useSelector((state) => state.user);

  const [isCustomerSearchModalOpen, setIsCustomerSearchModalOpen] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: vMediaQuery ? '80vh' : xMediaQuery ? '96vh' : '86.7vh',
    overflowY: 'auto',
    width: vMediaQuery ? 350 : 600,
    bgcolor: '#fff',
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'CustomerName' && value === '') {
      // Reset related fields when customerName is cleared
      setSelectedTask((prevState) => ({
        ...prevState,
        AcCd: '',
        T_ContactPerson: '',
        T_ContactNo: '',
        T_Email: '',
        [name]: value
      }));
    } else {
      setSelectedTask((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (date, name) => {
    setSelectedTask((prevState) => ({
      ...prevState,
      [name]: date ? dayjs(date).format('YYYY-MM-DD') : ''
    }));
  };

  const fQualify = async () => {
    const payload = {
      T_ID: selectedTask?.T_ID,
      T_Loc: selectedTask?.Loc,
      T_Title: selectedTask?.T_Title,
      T_Desc: selectedTask?.T_Desc,
      T_DueDate: selectedTask?.T_DueDate,
      T_Priority: selectedTask?.T_Priority,
      T_Customer: selectedTask?.AcCd,
      T_ContactPerson: selectedTask?.T_ContactPerson,
      T_ContactNo: selectedTask?.T_ContactNo,
      T_Email: selectedTask?.T_Email,
      T_Dept: selectedTask?.DepartmentCode,
      T_Assignee: selectedTask?.AssigneeCode,
      T_Supervisor: selectedTask?.SupervisorCode,
      T_TeamLead: selectedTask?.TeamLeadCode,
      T_EnBy: vLoginData?.userCd,
      T_InformCust: selectedTask?.T_InformCust,
      T_Project: selectedTask?.ProjectCode,
      T_FlexiDays: selectedTask?.T_FlexiDays,
      T_Type: selectedTask?.T_Type,
      T_ModuleDetail: selectedTask?.T_ModuleDetail
    };
    const result = await insertTaskAPI(payload);
    if (result.status === 200) {
      const obj = {
        Task_id: selectedTask?.T_ID,
        User_id: user?.userCd
      };

      const result = await qualifyTaskAPI(obj);
      if (result.status === 200) {
        fAlertToast('SUCCESS', 'Task Sucessfully qualified');
        // Remove the deleted task from the local state before reloading the tasks
        const updatedTasks = pendingQualifiedTasks.filter(
          (task) => task.T_ID !== selectedTask?.T_ID
        );

        setPendingQualifiedTasks(updatedTasks); // Update local state to reflect deleted task

        // Call the API to reload tasks
        await fPendingTaskQualify();

        // Check if search input has value, then apply the filter again
        if (searchInputValue) {
          const filtered = updatedTasks.filter((task) => {
            return (
              task.T_Desc.toLowerCase().includes(searchInputValue.toLowerCase()) ||
              task.Assignee.toLowerCase().includes(searchInputValue.toLowerCase())
            );
          });
          setFilteredQualifyTasks(filtered); // Update the filtered tasks after deletion
        } else {
          setFilteredQualifyTasks(updatedTasks); // Set all tasks if no search input
        }

        fPendingTaskAccept();
        handleClose();
      }
      setIsDrawerDataChange(true);
    } else {
      fAlertToast('FAILED', "Task doesn't qualified");
    }
  };

  const fReject = async () => {
    const obj = {
      Task_id: selectedTask?.T_ID,
      User_id: user?.userCd
    };

    const result = await deleteTaskAPI(obj);

    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Task deleted successfully');

      // Remove the deleted task from the local state before reloading the tasks
      const updatedTasks = pendingQualifiedTasks.filter((task) => task.T_ID !== selectedTask?.T_ID);

      setPendingQualifiedTasks(updatedTasks); // Update local state to reflect deleted task

      // Call the API to reload tasks
      await fPendingTaskQualify();

      // Check if search input has value, then apply the filter again
      if (searchInputValue) {
        const filtered = updatedTasks.filter((task) => {
          return (
            task.T_Desc.toLowerCase().includes(searchInputValue.toLowerCase()) ||
            task.Assignee.toLowerCase().includes(searchInputValue.toLowerCase())
          );
        });
        setFilteredQualifyTasks(filtered); // Update the filtered tasks after deletion
      } else {
        setFilteredQualifyTasks(updatedTasks); // Set all tasks if no search input
      }

      handleClose(); // Close the modal
    } else {
      fAlertToast('FAILED', "Task doesn't qualified");
    }
  };

  const searchCustomerModalClose = () => {
    setIsCustomerSearchModalOpen(false);

    setTimeout(() => {
      document.getElementById('CustomerName')?.focus();
    }, 500);
  };

  const handleSearchCustomer = (e) => {
    if (e.key === 'Enter' || e._reactName === 'onClick') {
      setIsCustomerSearchModalOpen(true);
    }

    setTimeout(() => {
      document.getElementById('accountName')?.focus();
    }, 500);
  };

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Box sx={style}>
        <div className="modal-head">
          <Typography variant="subtilte1" sx={{ margin: 0 }}>
            Task Details
          </Typography>

          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </div>
        <Grid
          className="task-detail"
          container
          spacing={2}
          sx={{ paddingY: vMediaQuery ? 2 : 3, paddingX: vMediaQuery ? 3 : 4 }}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField fullWidth label="Task Id" name="T_ID" value={selectedTask?.T_ID} disabled />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Location</InputLabel>
              <Select
                name="location"
                value={selectedTask?.Loc}
                onChange={handleChange}
                label="Team Lead">
                {location?.map((user) => (
                  <MenuItem key={user.Uid} value={user.Uid}>
                    {user.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              label="Title"
              id="T_Title"
              name="T_Title"
              multiline
              value={selectedTask?.T_Title}
              onChange={handleChange}
              rows={1}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              label="Description"
              name="T_Desc"
              value={selectedTask?.T_Desc}
              onChange={handleChange}
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Due Date"
                name="T_DueDate"
                value={selectedTask?.T_DueDate}
                inputFormat="DD/MM/YYYY"
                onChange={(date) => handleDateChange(date, 'T_DueDate')}
                renderInput={(params) => (
                  <TextField {...params} fullWidth id="dueDate" className="duedate" />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              fullWidth
              label="Extended Day"
              name="T_FlexiDays"
              id="T_FlexiDays"
              type="number"
              value={selectedTask?.T_FlexiDays}
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  style: {
                    MozAppearance: 'textfield'
                  }
                },
                sx: {
                  '& input[type=number]': {
                    MozAppearance: 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0
                    }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                name="T_Priority"
                value={selectedTask?.T_Priority}
                onChange={handleChange}
                label="Priority">
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Critical">Critical</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4.6}>
            <FormControl fullWidth>
              <InputLabel>Types of Task</InputLabel>
              <Select
                name="T_Type"
                value={selectedTask?.T_Type}
                onChange={handleChange}
                label="Types of Task">
                {typTasks.map((typ) => (
                  <MenuItem key={typ} value={typ}>
                    {typ}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7.4}>
            <TextField
              fullWidth
              label="Module"
              name="T_ModuleDetail"
              id="T_ModuleDetail"
              value={selectedTask?.T_ModuleDetail}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <TextField
              fullWidth
              label="Customer Code"
              name="AcCd"
              value={selectedTask?.AcCd || ''}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <TextField
              fullWidth
              label="Customer"
              name="CustomerName"
              id="CustomerName"
              value={selectedTask?.CustomerName}
              onChange={handleChange}
              onKeyDown={handleSearchCustomer}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearchCustomer}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Contact Person"
              name="T_ContactPerson"
              value={selectedTask?.T_ContactPerson}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Contact No"
              name="T_ContactNo"
              value={selectedTask?.T_ContactNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Contact Email"
              name="T_Email"
              value={selectedTask?.T_Email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                name="DepartmentCode"
                value={selectedTask?.DepartmentCode}
                onChange={handleChange}
                label="Department">
                {depts.map((dept) => (
                  <MenuItem key={dept.UId} value={dept.UId}>
                    {dept.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Project</InputLabel>
              <Select
                name="ProjectCode"
                value={selectedTask?.ProjectCode || ''}
                onChange={handleChange}
                label="Project">
                {projects.map((project) => (
                  <MenuItem key={project.UId} value={project.UId}>
                    {project.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Assignee</InputLabel>
              <Select
                name="AssigneeCode"
                value={selectedTask?.AssigneeCode}
                onChange={handleChange}
                label="Assignee">
                {users.map((user) => (
                  <MenuItem key={user.UId} value={user.UId}>
                    {user.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Supervisor</InputLabel>
              <Select
                name="SupervisorCode"
                value={selectedTask?.SupervisorCode}
                onChange={handleChange}
                label="Supervisor">
                {users.map((user) => (
                  <MenuItem key={user.UId} value={user.UId}>
                    {user.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Team Lead</InputLabel>
              <Select
                name="TeamLeadCode"
                value={selectedTask?.TeamLeadCode}
                onChange={handleChange}
                label="Team Lead">
                {users.map((user) => (
                  <MenuItem key={user.UId} value={user.UId}>
                    {user.Name1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Created By"
              name="CreatedBy"
              value={selectedTask?.CreatedBy}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              label="Entry Date"
              name="T_EnDt"
              value={moment(selectedTask?.T_EnDt).format('DD/MM/YYYY')}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              name="T_InformCust"
              control={<Checkbox checked={selectedTask?.T_InformCust === 'Y'} readOnly />}
              label="Inform Customer once work is done"
            />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <CmpButton xbtnName={'Qualify'} xOnClick={fQualify} />
            <CmpButtonError xbtnName={'Reject'} xOnClick={fReject} />
          </Grid>
        </Grid>
        <CustomerSearchModal
          open={isCustomerSearchModalOpen}
          handleClose={searchCustomerModalClose}
          handleSearch={handleSearchCustomer}
          updateCustomerName={selectedTask?.CustomerName}
          setSelectedTask={setSelectedTask}
          vMediaQuery={vMediaQuery}
        />
      </Box>
    </Modal>
  );
};

export default TaskDetailModal;
