import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  useMediaQuery,
  Drawer,
  IconButton,
  TextField,
  InputAdornment,
  Badge
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import theme from '../theme.js';
import moment from 'moment';
import {
  getPendingQualifiedTasksAPI,
  pendingTaskAPI,
  pendingTaskSummaryAssigneeAPI,
  pendingTaskSummarySupervisorAPI,
  taskStatisticsAPI
} from '../Action.jsx';
import '../components/pages.css';
import TaskDetailModal from './TaskDetailModal.jsx';
import { useSelector } from 'react-redux';
import TaskDetailDrawer from './TaskDetailDrawer.jsx';
import MyTableCell from '../commonComponents/MyTableCell.js';
import SearchIcon from '@mui/icons-material/Search';
import ActivityNotification from './ActivityNotification.jsx';
import * as XLSX from 'xlsx'; // Import for Excel export
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Home = () => {
  const vMediaQuery = useMediaQuery('(max-width:900px)');
  const xMediaQuery = useMediaQuery('(max-width:600px)');
  const bMediaQuery = useMediaQuery('(min-width:900px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state.user);

  const [pendingQualifiedTasks, setPendingQualifiedTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pendingTaskAccept, setPendingTaskAccept] = useState([]);
  const [selectedAccTask, setSelectedAccTask] = useState('');
  const [pendingTaskAssignee, setPendingTaskAssignee] = useState([]);
  const [pendingTaskSupervisor, setPendingTaskSupervisor] = useState([]);
  const [taskStatistics, setTaskStatistics] = useState([]);
  const [isDrawerDataChange, setIsDrawerDataChange] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [filter, setFilter] = useState('');
  const [sFocused, setSfocused] = useState(false);
  const [sSearch, setSearch] = useState('');
  const [searchedAcceptTasks, setSearchedAcceptTasks] = useState([]);
  const [filteredQualifyTasks, setFilteredQualifyTasks] = useState([]);

  // Assuming this fetches data and sets it to pendingQualifiedTasks
  useEffect(() => {
    setFilteredQualifyTasks(pendingQualifiedTasks); // Initialize with all tasks
  }, [pendingQualifiedTasks]);

  const handleQualifySearch = (event) => {
    const value = event.target.value;
    setFilter(value);

    const filtered = pendingQualifiedTasks.filter((task) => {
      return (
        task.T_Desc.toString().toLowerCase().includes(value.toLowerCase()) ||
        task.Assignee.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilteredQualifyTasks(filtered);
  };

  // Assuming this fetches data and sets it to pendingQualifiedTasks
  useEffect(() => {
    setSearchedAcceptTasks(pendingTaskAccept); // Initialize with all tasks
  }, [pendingTaskAccept]);

  const handleAcceptSearch = (event) => {
    const value = event.target.value;
    setSearch(value);

    const filtered = pendingTaskAccept.filter((task) => {
      return (
        task.T_Desc.toString().toLowerCase().includes(value.toLowerCase()) ||
        task.Assignee.toLowerCase().includes(value.toLowerCase())
      );
    });

    setSearchedAcceptTasks(filtered);
  };

  const users = user?.filterData?.map((u) => u.UId).join(',') || '';

  const fTaskStatistics = async () => {
    const obj = {
      Assignee: users
    };
    const result = await taskStatisticsAPI(obj);

    if (result.status === 200) {
      const formattedData = result.data.map((item) => ({
        Date: moment(item.Date).format('DD/MM/YYYY'),
        ClosedJobCount: item.ClosedJobCount,
        PendingJobCount: item.PendingJobCount
      }));
      setTaskStatistics(formattedData);
    }
  };

  const fPendingTaskAssignee = async () => {
    const obj = {
      Assignee: users
    };
    const result = await pendingTaskSummaryAssigneeAPI(obj);

    if (result.status === 200) {
      setPendingTaskAssignee(result.data);
    }
  };

  const fPendingTaskSupervisor = async () => {
    const obj = {
      Assignee: users
    };
    const result = await pendingTaskSummarySupervisorAPI(obj);

    if (result.status === 200) {
      setPendingTaskSupervisor(result.data);
    }
  };

  const fPendingTaskQualify = async () => {
    const obj = {
      User_id: user?.userCd
    };
    const result = await getPendingQualifiedTasksAPI(obj);
    if (result.status === 200) {
      setPendingQualifiedTasks(result.data); // Store full data

      // Apply the current search filter after fetching tasks
      if (filter) {
        const filtered = result.data.filter((task) => {
          return (
            task.T_Desc.toLowerCase().includes(filter.toLowerCase()) ||
            task.Assignee.toLowerCase().includes(filter.toLowerCase())
          );
        });
        setFilteredQualifyTasks(filtered); // Store filtered data
      } else {
        setFilteredQualifyTasks(result.data); // If no filter, set all tasks
      }
    }
  };

  const handleRowClick = (row) => {
    // Step 1: Check if the user is not an admin
    console.log(user.Superadmin);
    
    if (user?.Admin === 'N' && user.Superadmin === 'N') {
      return; // Do nothing if user is not admin
    }

    // Step 2: Check for specific task types and user code
    if (
      user?.Admin !== 'Y' && 
      [
        'Software Enhancement',
        'Software Quotation',
        'Bugs Fixing',
        'New Features',
        'Report Development'
      ].includes(row?.T_Type)
    ) {
      confirmAlert({
        title: 'Qualify',
        message: 'This task is related to software development, only Admin can approve',
        buttons: [
          {
            className: 'alertBtn',
            label: 'OK',
            onClick: () => {}
          }
        ],
        //  overlayClassName: 'custom-confirm-alert-overlay',
        className: 'custom-confirm-alert'
      });
      return;
    }
    // Step 3: Open the TaskDetailModal
    setSelectedTask(row); // Set the task in the modal
    setSelectedTask((prevObj) => ({
      ...prevObj,
      T_DueDate: moment(row.T_DueDate).format('YYYY-MM-DD')
    }));
    setIsModalOpen(true); // Open the modal

    setTimeout(() => {
      document.getElementById('T_Title')?.focus();
    }, 500);
  };

  const handleModalClose = () => {
    if (isDrawerDataChange) {
      fPendingTaskQualify();
      fPendingTaskAccept();
      setIsDrawerDataChange(true);
    }
    setIsDrawerDataChange(false);
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  const fPendingTaskAccept = async () => {
    const obj = {
      User_id: users,
      T_Status: 'OPEN'
    };
    const result = await pendingTaskAPI(obj);

    if (result.status === 200) {
      setPendingTaskAccept(result.data);

      // Apply the current search filter after fetching tasks
      if (sSearch) {
        const filtered = result.data.filter((task) => {
          return (
            task.T_Desc.toLowerCase().includes(sSearch.toLowerCase()) ||
            task.Assignee.toLowerCase().includes(sSearch.toLowerCase())
          );
        });
        setSearchedAcceptTasks(filtered); // Store filtered data
      } else {
        setSearchedAcceptTasks(result.data); // If no filter, set all tasks
      }
    }
  };

  const handleDrawerClose = () => {
    if (isDrawerDataChange) {
      fPendingTaskAccept();
      setIsDrawerDataChange(true);
    }
    setIsDrawerOpen(false);
    setIsDrawerDataChange(false);
  };

  const handleDrawer = (item) => {
    setSelectedAccTask(item);

    setSelectedAccTask((prevObj) => ({
      ...prevObj,
      T_DueDate: moment(item.T_DueDate).format('YYYY-MM-DD')
    }));
    setIsDrawerOpen(true);

    setTimeout(() => {
      document.getElementById('dueDate')?.focus();
    }, 500);
  };

  // Handle Excel Export PendingTaskbyAsginee
  const exportPendingTaskbyAsginee = () => {
    const workSheet = XLSX.utils.json_to_sheet(pendingTaskAssignee);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PendingTaskbyAsginee');
    XLSX.writeFile(workBook, 'PendingTaskbyAsginee_data.xlsx');
  };
  // Handle Excel Export PendingTaskbySupervior
  const exportPendingTaskbySupervior = () => {
    const workSheet = XLSX.utils.json_to_sheet(pendingTaskSupervisor);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PendingTaskbySupervior');
    XLSX.writeFile(workBook, 'PendingTaskbySupervior_data.xlsx');
  };
  // Handle Excel Export PendingAcceptTask
  const exportPendingAcceptTask = () => {
    const workSheet = XLSX.utils.json_to_sheet(pendingTaskAccept);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PendingAcceptTasks');
    XLSX.writeFile(workBook, 'PendingAcceptTask_data.xlsx');
  };
  // Handle Excel Export PendingQualifyTask
  const exportPendingQualifyTask = () => {
    const workSheet = XLSX.utils.json_to_sheet(pendingQualifiedTasks);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PendingQualifyTasks');
    XLSX.writeFile(workBook, 'PendingQualifyTask_data.xlsx');
  };

  useEffect(() => {
    fTaskStatistics();
    fPendingTaskAssignee();
    fPendingTaskSupervisor();
    fPendingTaskAccept();
    fPendingTaskQualify();
  }, [user?.filterData]);

  return (
    <Box
      sx={{
        backgroundColor: xMediaQuery ? theme.palette.background.page : ''
      }}>
      <Grid container>
        <Grid
          item
          xs={isMobile ? 12 : 9}
          sm={isMobile ? 12 : 9}
          md={isMobile ? 12 : 9}
          lg={isMobile ? 12 : 9.5}
          className="home">
          <Typography variant="h4" color={theme.palette.primary.main} gutterBottom>
            Dashboard
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
                <LineChart
                  data={taskStatistics}
                  margin={{
                    top: 5,
                    right: 30,
                    left: isMobile ? -20 : vMediaQuery ? -10 : 20,
                    bottom: 5
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="ClosedJobCount"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="PendingJobCount" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="search-field">
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px' }}>
                  Pending Task by Assignee
                </Typography>
                <Box className="exportBox" sx={{ marginBottom: '6px' }}>
                  <IconButton onClick={exportPendingTaskbyAsginee}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                  <Typography variant="caption" sx={{ mt: -1 }}>
                    Export
                  </Typography>
                </Box>
              </div>
              <TableContainer className="table" component={Paper}>
                <Table
                  size={isMobile ? 'small' : 'medium'}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderRight: theme.palette.tblColBorder,
                      textAlign: 'center'
                    }
                  }}
                  stickyHeader>
                  <TableHead>
                    <TableRow className="table-head">
                      {/* <TableCell>Assignee Code</TableCell> */}
                      <TableCell>Assignee</TableCell>
                      <TableCell>Open</TableCell>
                      <TableCell>InProgress</TableCell>
                      <TableCell>Verified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingTaskAssignee.map((row, index) => (
                      <TableRow
                        key={index}
                        className="table-body"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#c4c4c40f'
                          }
                        }}>
                        {/* <TableCell>{row.Assignee}</TableCell> */}
                        <TableCell>{row.AssigneeName}</TableCell>
                        <TableCell>{row.OpenTasks}</TableCell>
                        <TableCell>{row.InProgressTasks}</TableCell>
                        <TableCell>{row.VerifiedTasks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="search-field">
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px' }}>
                  Pending Task by Supervisor
                </Typography>
                <Box className="exportBox" sx={{ marginBottom: '6px' }}>
                  <IconButton onClick={exportPendingTaskbySupervior}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                  <Typography variant="caption" sx={{ mt: -1 }}>
                    Export
                  </Typography>
                </Box>
              </div>
              <TableContainer className="table" component={Paper}>
                <Table
                  size={isMobile ? 'small' : 'medium'}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderRight: theme.palette.tblColBorder,
                      textAlign: 'center'
                    }
                  }}
                  stickyHeader>
                  <TableHead>
                    <TableRow className="table-head">
                      {/* <TableCell>Supervisor Code</TableCell> */}
                      <TableCell>Supervisor</TableCell>
                      <TableCell>Open </TableCell>
                      <TableCell>InProgress</TableCell>
                      <TableCell>Verified</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingTaskSupervisor.map((row, index) => (
                      <TableRow
                        key={index}
                        className="table-body"
                        sx={{
                          '&:hover': {
                            backgroundColor: '#c4c4c40f'
                          }
                        }}>
                        {/* <TableCell>{row.Supervisor}</TableCell> */}
                        <TableCell>{row.SupervisorName}</TableCell>
                        <TableCell>{row.OpenTasks}</TableCell>
                        <TableCell>{row.InProgressTasks}</TableCell>
                        <TableCell>{row.VerifiedTasks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="search-field">
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px' }}>
                  Pending Task to Accept
                </Typography>
                <div className="exportDiv">
                  <TextField
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px 14px !important'
                      },
                      '& .MuiFormLabel-root': {
                        top: '-7px !important'
                      },
                      '& .MuiInputBase-root': {
                        paddingRight: '0px !important',
                        borderRadius: '20px !important',
                        transition: 'all 0.4s ease',
                        width: sFocused ? '100%' : '70px'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: sFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: sFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border on hover
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: sFocused ? '1px solid #1976d2' : 'none' // Hide the border when focused
                      }
                    }}
                    id="filter"
                    variant="outlined"
                    margin="normal"
                    placeholder={sFocused ? 'Search...' : ''}
                    value={sSearch}
                    onFocus={() => {
                      setSfocused(true);
                    }}
                    onChange={handleAcceptSearch}
                    onBlur={() => {
                      if (!sSearch) {
                        setSfocused(false);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setSfocused(true);
                            }}
                            sx={{
                              border: sFocused ? 'none' : '1px solid #dbd1d1',
                              padding: '6px !important',
                              backgroundColor: sFocused ? 'transparent' : 'white'
                            }}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box className="exportBox">
                    <IconButton onClick={exportPendingAcceptTask}>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                    <Typography variant="caption" sx={{ mt: -1 }}>
                      Export
                    </Typography>
                  </Box>
                </div>
              </div>
              <TableContainer component={Paper} className="table">
                <Table
                  size={isMobile ? 'small' : 'medium'}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderRight: theme.palette.tblColBorder,
                      textAlign: 'left'
                    }
                  }}
                  stickyHeader>
                  <TableHead>
                    <TableRow className="table-head">
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: bMediaQuery ? 0 : '',
                          zIndex: bMediaQuery ? 3 : '',
                          backgroundColor: bMediaQuery ? '#eef1fd' : ''
                        }}>
                        Description
                      </TableCell>
                      <TableCell>Assignee</TableCell>
                      <TableCell>Remainder</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Contact Person</TableCell>
                      <TableCell>Contact No</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Project</TableCell>
                      <TableCell>Supervisor</TableCell>
                      <TableCell>Team Lead</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Inform Customer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchedAcceptTasks.map((row, index) => (
                      <TableRow
                        className="table-body"
                        key={index}
                        sx={{
                          color: `${row.Modified === 1 ? '#010000' : ''} !important`,
                          '& .MuiTableCell-root': {
                            fontWeight: `${row.Modified === 1 ? 600 : ''} !important`
                          },
                          '&:hover': {
                            backgroundColor: `${row.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`
                          }
                        }}
                        onClick={() => handleDrawer(row)}>
                        <MyTableCell
                          className="table-descrip"
                          text={row.T_Desc}
                          vMediaQuery={vMediaQuery}
                          filteredTasks={searchedAcceptTasks}
                        />
                        <TableCell>{row.Assignee}</TableCell>
                        <TableCell>
                          {' '}
                          <div className="zone-chip">
                            {row.T_Reminder === 0 ? (
                              <>-</>
                            ) : (
                              <Badge
                                badgeContent={row.T_Reminder}
                                color="primary"
                                overlap="circular"></Badge>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{moment(row.T_DueDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{row.T_Priority}</TableCell>
                        <TableCell>{row.CustomerName}</TableCell>
                        <TableCell>{row.T_ContactPerson}</TableCell>
                        <TableCell>{row.T_ContactNo}</TableCell>
                        <TableCell>{row.T_Email}</TableCell>
                        <TableCell>{row.Department}</TableCell>
                        <TableCell>{row.ProjectName}</TableCell>
                        <TableCell>{row.Supervisor}</TableCell>
                        <TableCell>{row.TeamLead}</TableCell>
                        <TableCell>{row.LocName}</TableCell>
                        <TableCell>{row.CreatedBy}</TableCell>
                        <TableCell>{moment(row.CreatedDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{row.T_InformCust === 'Y' ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* {(user?.Admin === 'Y' || user?.Superadmin === 'Y') && ( */}
            <Grid item xs={12} md={12}>
              <div className="search-field">
                <Typography variant="h6" gutterBottom sx={{ fontSize: '18px' }}>
                  Pending Task to Qualify
                </Typography>
                <div className="exportDiv">
                  <TextField
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '10px 14px !important'
                      },
                      '& .MuiFormLabel-root': {
                        top: '-7px !important'
                      },
                      '& .MuiInputBase-root': {
                        paddingRight: '0px !important',
                        borderRadius: '20px !important',
                        transition: 'all 0.4s ease',
                        width: isFocused ? '100%' : '70px'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: isFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: isFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border on hover
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: isFocused ? '1px solid #1976d2' : 'none' // Hide the border when focused
                      }
                    }}
                    id="filter"
                    variant="outlined"
                    margin="normal"
                    placeholder={isFocused ? 'Search...' : ''}
                    value={filter}
                    onFocus={() => {
                      setIsFocused(true);
                    }}
                    onChange={handleQualifySearch}
                    onBlur={() => {
                      if (!filter) {
                        setIsFocused(false);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setIsFocused(true);
                            }}
                            sx={{
                              border: isFocused ? 'none' : '1px solid #dbd1d1',
                              padding: '6px !important',
                              backgroundColor: isFocused ? 'transparent' : 'white'
                            }}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box className="exportBox">
                    <IconButton onClick={exportPendingQualifyTask}>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                    <Typography variant="caption" sx={{ mt: -1 }}>
                      Export
                    </Typography>
                  </Box>
                </div>
              </div>
              <TableContainer className="table" component={Paper}>
                <Table
                  size={isMobile ? 'small' : 'medium'}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderRight: theme.palette.tblColBorder,
                      textAlign: 'left'
                    }
                  }}
                  stickyHeader>
                  <TableHead>
                    <TableRow className="table-head">
                      {/* <TableCell>Task ID</TableCell> */}
                      {/* <TableCell>Title</TableCell> */}
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: bMediaQuery ? 0 : '',
                          zIndex: bMediaQuery ? 3 : '',
                          backgroundColor: bMediaQuery ? '#eef1fd' : ''
                        }}>
                        Description
                      </TableCell>
                      <TableCell>Assignee</TableCell>
                      <TableCell>Remainder</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Contact Person</TableCell>
                      <TableCell>Contact No</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Project</TableCell>
                      <TableCell>Supervisor</TableCell>
                      <TableCell>Team Lead</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Entry By</TableCell>
                      <TableCell>Infrom Customer</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredQualifyTasks.map((row, index) => (
                      <TableRow
                        className="table-body"
                        key={index}
                        sx={{
                          color: `${row.Modified === 1 ? '#010000' : ''} !important`,
                          '& .MuiTableCell-root': {
                            fontWeight: `${row.Modified === 1 ? 600 : ''} !important`
                          },
                          '&:hover': {
                            backgroundColor: `${row.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`
                          }
                        }}
                        onClick={() => handleRowClick(row)}>
                        {/* <TableCell>{row.T_ID}</TableCell> */}
                        {/* <TableCell>{row.T_Title}</TableCell> */}
                        <MyTableCell
                          className="table-descrip"
                          text={row.T_Desc}
                          vMediaQuery={vMediaQuery}
                          filteredTasks={filteredQualifyTasks}
                        />
                        <TableCell>{row.Assignee}</TableCell>
                        <TableCell>
                          {' '}
                          <div className="zone-chip">
                            {row.T_Reminder === 0 ? (
                              <>-</>
                            ) : (
                              <Badge
                                badgeContent={row.T_Reminder}
                                color="primary"
                                overlap="circular"></Badge>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {row.T_DueDate ? moment(row.T_DueDate).format('DD/MM/YYYY') : ''}
                        </TableCell>
                        <TableCell>{row.T_Priority}</TableCell>
                        <TableCell>{row.CustomerName}</TableCell>
                        <TableCell>{row.T_ContactPerson}</TableCell>
                        <TableCell>{row.T_ContactNo}</TableCell>
                        <TableCell>{row.Department}</TableCell>
                        <TableCell>{row.ProjectName}</TableCell>
                        <TableCell>{row.Supervisor}</TableCell>
                        <TableCell>{row.TeamLead}</TableCell>
                        <TableCell>{row.LocName}</TableCell>
                        <TableCell>{row.CreatedBy}</TableCell>
                        <TableCell>
                          {row.T_EnDt ? moment(row.T_EnDt).format('DD/MM/YYYY') : ''}
                        </TableCell>
                        <TableCell>{row.T_InformCust}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* )} */}
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={3} sm={3} md={3} lg={2.5} className="home-notifySide">
            <ActivityNotification user={user} />
          </Grid>
        )}
      </Grid>
      <Drawer
        className="modal"
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: xMediaQuery ? 300 : 500,
            borderTopLeftRadius: '10px',
            overflowX: 'hidden',
            top: 'auto',
            bottom: 0,
            backgroundColor: theme.palette.background.drawer,
            borderBottom: 'none !important',
            borderRight: 'none !important',
            border: `${theme.palette.shadow.border}`,
            boxShadow: theme.palette.shadow.main,
            height: `calc(100vh - 64px) !important`
          }
        }}
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}>
        <TaskDetailDrawer
          task={selectedAccTask}
          setSelectedAccTask={setSelectedAccTask}
          handleClose={handleDrawerClose}
          xMediaQuery={xMediaQuery}
          vMediaQuery={vMediaQuery}
          fPendingTaskAccept={fPendingTaskAccept}
          isDrawerDataChange={isDrawerDataChange}
          setIsDrawerDataChange={setIsDrawerDataChange}
          searchInputValue={sSearch}
          pendingTaskAccept={pendingTaskAccept}
          setPendingTaskAccept={setPendingTaskAccept}
          setSearchedAcceptTasks={setSearchedAcceptTasks}
        />
      </Drawer>

      <TaskDetailModal
        open={isModalOpen}
        handleClose={handleModalClose}
        selectedTask={selectedTask}
        fPendingTaskQualify={fPendingTaskQualify}
        fPendingTaskAccept={fPendingTaskAccept}
        setSelectedTask={setSelectedTask}
        setIsDrawerDataChange={setIsDrawerDataChange}
        searchInputValue={filter}
        pendingQualifiedTasks={pendingQualifiedTasks}
        setPendingQualifiedTasks={setPendingQualifiedTasks}
        setFilteredQualifyTasks={setFilteredQualifyTasks}
      />
    </Box>
  );
};

export default Home;
