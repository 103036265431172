import { toast } from 'react-toastify';

const fAlertToast = (xIsSuccess, xMessage) => {
  let vToasterStyle = {
    position: 'top-right',
    autoClose: xIsSuccess === 'SUCCESS' ? 5000 : 8000, // Auto close after 5s for success, 8s for others
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  switch (xIsSuccess) {
    case 'SUCCESS':
      return toast.success(xMessage, vToasterStyle);
    case 'FAILED':
      return toast.error(xMessage, vToasterStyle);
    case 'WARNING':
      return toast.warning(xMessage, vToasterStyle);
    case 'INFO':
      return toast.info(xMessage, vToasterStyle);
    default:
      return toast.info(xMessage, vToasterStyle);
  }
};

export { fAlertToast };
