import React, { useEffect, useState } from 'react';
import theme from '../theme.js';
import { Box, Card, CardContent, Drawer, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { todayNotificationAPI, updateTaskReadStatusAPI } from '../Action.jsx';
import { setUnReadNotifications } from '../Slice/userSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const NotificationDrawer = ({ notifyDrawer, handleNotifyDrawerClose, xMediaQuery, user }) => {
  const [expandedDes, setExpandedDes] = useState(null);
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const unRead = useSelector((state) => state.user.unreadNotifications);

  const notifications = async () => {
    const obj = {
      user_id: user?.userCd
    };
    const result = await todayNotificationAPI(obj);

    if (result.status === 200) {
      setNotificationData(result.data.message);

      setUnreadCount(result?.data?.message.filter((n) => n.N_IsRead === null).length); // Set unread count
      dispatch(setUnReadNotifications(unreadCount));
    }
  };

  const updateReadStatus = async (N_ID) => {
    const obj = {
      N_ID: N_ID
    };

    const result = await updateTaskReadStatusAPI(obj);

    if (result.status === 200) {
      notifications();
    }
  };

  const handleExpendedIcon = (index, N_ID, N_IsRead) => {
    setExpandedDes(expandedDes === index ? null : index);
    if (N_IsRead === null) {
      updateReadStatus(N_ID);
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  useEffect(() => {
    notifications(); 
  }, [unreadCount]);

  useEffect(() => {
    if (notifyDrawer) {
      notifications();
    }
  }, [notifyDrawer, user?.userCd]);

  useEffect(() => {
    notifications(); // Trigger notification refresh when unread count changes
  }, [unRead]);

  return (
    <Drawer
      className="modal"
      anchor="right"
      open={notifyDrawer}
      onClose={handleNotifyDrawerClose}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width: xMediaQuery ? 250 : 350,
          paddingX: xMediaQuery ? 1 : '',
          borderTopLeftRadius: '8px',
          borderRight: 'none !important',
          borderBottom: 'none !important',
          border: theme.palette.shadow.border,
          boxShadow: theme.palette.shadow.main,
          backgroundColor: '#fbfcff',
          overflowX: 'hidden',
          top: 'auto',
          bottom: 0,
          height: `calc(100vh - 64px) !important`
        }
      }}>
      <Box
        sx={{
          paddingX: xMediaQuery ? '0px' : 2,
          paddingY: xMediaQuery ? '0px' : 2
        }}
        className="task-detail">
        <Typography variant="h6">Notifications</Typography>

        {notificationData?.map((notification, index) => (
          <Card
            className="notification-card"
            key={index}
            sx={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              border: ` ${theme.palette.shadow.borderClr} !important`,
              borderRadius: '8px',
              maxWidth: 400,
              margin: '8px auto',
              padding: '8px'
            }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography className="notifyType" variant="body2">
                {notification.N_Typeofnotification}
              </Typography>
              {/* Icon button for expanding/collapsing */}
              <IconButton
                onClick={() => handleExpendedIcon(index, notification.N_ID, notification.N_IsRead)}
                sx={{ color: '#9e9e9e', marginLeft: 'auto', padding: '0px !important' }}>
                {expandedDes === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            <CardContent sx={{ padding: '0px 10px' }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '13px',
                  fontWeight: '500',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp:
                    expandedDes === index || notification?.N_Message.length <= 36 ? 'none' : 3, // Show full text if expanded
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace:
                    expandedDes === index || notification?.N_Message.length <= 36
                      ? 'normal'
                      : 'wrap'
                }}>
                {expandedDes === index
                  ? notification?.T_Title
                  : truncateText(notification?.T_Title, 36)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '13px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp:
                    expandedDes === index || notification?.N_Message.length <= 35 ? 'none' : 3, // Show full text if expanded
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace:
                    expandedDes === index || notification?.N_Message.length <= 35
                      ? 'normal'
                      : 'wrap'
                }}>
                {expandedDes === index
                  ? notification?.N_Message
                  : truncateText(notification?.N_Message, 35)}
              </Typography>
              <strong className="user-name"> By</strong>
              <strong className="user-name"> {notification.CreatedByName}</strong>
              <div className="aligntext">
                <Typography className="descriptDate" variant="body2">
                  {moment(notification.N_EnDt).format('DD/MM/YYYY')}{' '}
                  {moment(notification.N_EnDt).format('hh:mm A')}
                </Typography>
                <Typography
                  className="type"
                  color={notification.N_IsRead === 'Y' ? theme.palette.statusClr.open : '#9e9e9e'}
                  textAlign={'right'}>
                  {<DoneAllIcon />}
                </Typography>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
