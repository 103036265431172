import React from 'react';
import { Box, Grid, Typography, TextField, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../theme.js';
import '../components/pages.css';
import { CmpButton } from '../commonComponents/Toobox.js';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { acceptTaskAPI } from '../Action.jsx';
import { fAlertToast } from '../Utility/Utility.jsx';

const TaskDetailDrawer = ({
  task,
  handleClose,
  setSelectedAccTask,
  xMediaQuery,
  fPendingTaskAccept,
  setIsDrawerDataChange,
  searchInputValue,
  pendingTaskAccept,
  setPendingTaskAccept,
  setSearchedAcceptTasks
}) => {
  const aMediaQuery = useMediaQuery('(max-width:1100px)');
  const user = useSelector((state) => state.user);

  const handleDateChange = (date, name) => {
    setSelectedAccTask((prevState) => ({
      ...prevState,
      [name]: date ? dayjs(date).format('YYYY-MM-DD') : ''
    }));

    setTimeout(() => {
      document.getElementById('T_FlexiDays')?.focus();
    }, 800);
  };

  const fAccept = async () => {
    const obj = {
      Task_id: task?.T_ID,
      User_id: user?.userCd,
      DueDate: task?.T_DueDate
    };
    const result = await acceptTaskAPI(obj);

    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Task Accepted successfully');

      // Remove the deleted task from the local state before reloading the tasks
      const updatedTasks = pendingTaskAccept.filter((tasks) => tasks.T_ID !== task?.T_ID);

      setPendingTaskAccept(updatedTasks); // Update local state to reflect deleted task

      // Call the API to reload tasks
      await fPendingTaskAccept();

      // Check if search input has value, then apply the filter again
      if (searchInputValue) {
        const filtered = updatedTasks.filter((tasks) => {
          return (
            tasks.T_Desc.toLowerCase().includes(searchInputValue.toLowerCase()) ||
            tasks.Assignee.toLowerCase().includes(searchInputValue.toLowerCase())
          );
        });
        setSearchedAcceptTasks(filtered); // Update the filtered tasks after deletion
      } else {
        setSearchedAcceptTasks(updatedTasks); // Set all tasks if no search input
      }

      handleClose();
    }
    setIsDrawerDataChange(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="add-task">
        <Typography variant="h6" sx={{ margin: 0, color: theme.palette.primary.main }}>
          Task Detail
        </Typography>
        <CloseIcon
          onClick={handleClose}
          sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
        />
      </div>
      <Grid
        className="detail-drawer"
        container
        spacing={xMediaQuery ? 0.4 : aMediaQuery ? 1.4 : 2}
        sx={{ paddingX: xMediaQuery ? '10px' : '24px', pt: 1 }}>
        {/* Task ID */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Task ID</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_ID || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        {/* Title */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Title</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_Title || ''}
            InputProps={{
              readOnly: true
            }}
            multiline
          />
        </Grid>

        {/* Description */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2" marginTop={'4px'}>
            Description
          </Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2" marginTop={'4px'}>
            :
          </Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_Desc || ''}
            InputProps={{
              readOnly: true
            }}
            multiline
          />
        </Grid>

        {/* Due Date */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Due Date</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="T_DueDate"
              value={task?.T_DueDate}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => handleDateChange(date, 'T_DueDate')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="dueDate"
                  sx={{
                    '& .MuiInputBase-root': {
                      paddingRight: '6px '
                    }
                  }}
                  fullWidth
                  variant="standard"
                  // onKeyUp={(e) => {
                  //   if (e.key === 'Enter' ) {
                  //     setTimeout(() => {
                  //       document.getElementById('T_FlexiDays')?.focus();
                  //     }, 500);
                  //   }
                  // }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        {/* Priority */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Priority</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_Priority || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Customer Code */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Code</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.AcCd || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Customer Name */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Name</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.CustomerName || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Contact Person */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Person</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_ContactPerson || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Contact No */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact No</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_ContactNo || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Email</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_Title || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Department */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Department</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.Department || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Project Name */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Project</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.ProjectName || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Assignee */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Assignee</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.Assignee || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Supervisor */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Supervisor</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.Supervisor || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Team Lead */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Team Lead</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.TeamLead || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Location */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Location</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.LocName || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Created By */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Created By</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.CreatedBy || ''}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Entry Date */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Entry Date</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={moment(task?.CreatedDate).format('DD/MM/YYYY')}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        {/* Inform Customer */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Inform Customer</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.T_InformCust === 'Y' ? 'Yes' : 'No'}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <CmpButton xbtnName="Accept" xVariant="contained" xOnClick={fAccept} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskDetailDrawer;
