import { httpRequest } from "./Server/Axios";

export const LoginTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/bakeryPreBooking/login`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const insertTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/insertTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getPendingQualifiedTasksAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/pendingQualifiedTasks`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const qualifyTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/qualifyTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const deleteTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/deleteTask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const searchTaskCustomerAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/searchTaskCustomer`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const pendingTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/mypendingtask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  
  export const acceptTaskAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/acceptmytask`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const insertTaskActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/insertTaskActivity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const getTaskActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskActivity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const supervisingTasksAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/SupervisingTasks`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const pendingTaskSummaryAssigneeAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/pendingSummaryAssignee`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };
    
  export const pendingTaskSummarySupervisorAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/PendingTaskSummarySupervisor`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskStatisticsAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/taskStatistics`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const taskReminderAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/TaskReminder`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  
  export const todayActivityAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/today-activity`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const todayNotificationAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/task-notification`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateTaskReadStatusAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/update-task-read-status`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const salesLeadSummaryAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/SalesLeadSummary`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const updateSalesLeadAPI = async (apiObj) => {
    let apiMethod = {
      method: "POST",
      url: `/task/updateSalesLead`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };

  export const attributesAPI = async (apiObj) => {
    let apiMethod = {
      method: "GET",
      url: `/task/fAttributes`,
      data: apiObj, 
    };
    var Data = await httpRequest(apiMethod);
  
    return Data;
  };