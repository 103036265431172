import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useMediaQuery,
  Grid,
  Badge,
  Fade,
  styled,
  Tooltip,
  tooltipClasses,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import theme from '../theme.js';
import '../components/pages.css';
import { supervisingTasksAPI } from '../Action.jsx';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CmpTypographyField } from '../commonComponents/Toobox.js';
import SearchIcon from '@mui/icons-material/Search';
import SupervisingDetailTask from './SupervisingDetailTask.js';
import UpdateFollowUpDrawer from './UpdateFollowUpDrawer.js';
import MyTableCell from '../commonComponents/MyTableCell.js';
import RemainderModal from './RemainderModal.js';
import * as XLSX from 'xlsx'; // Import for Excel export
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const badgeStyle = {
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.primary.text}`,
    top: '6% !important',
    height: '21px !important'
  }
};

const SupervisingTask = () => {
  const xMediaQuery = useMediaQuery('(max-width:450px)');
  const vMediaQuery = useMediaQuery('(max-width:900px)');
  const bMediaQuery = useMediaQuery('(min-width:900px)');
  const uMediaQuery = useMediaQuery('(max-width:1200px)');
  const aMediaQuery = useMediaQuery('(max-width:1450px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useSelector((state) => state.user);
  const apiCallCounts = useSelector((state) => state.user?.refreshApiCall);

  const [supervisingData, setSupervisingData] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [uniquePriorities, setUniquePriorities] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [priorityCounts, setPriorityCounts] = useState({});
  const [uniqueLabels, setUniqueLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [zoneCounts, setZoneCounts] = useState({});
  const [isDrawerDataChange, setIsDrawerDataChange] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [remainderModal, setRemainderModal] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const [filter, setFilter] = useState('');

  const chipsStyle = {
    '&.MuiChip-root': {
      height: '26px',
      fontSize: '12px',
      marginLeft: xMediaQuery ? '2px' : '6px',
      marginRight: xMediaQuery ? '2px' : '6px',
      width: xMediaQuery
        ? '76px'
        : vMediaQuery
        ? '100px'
        : uMediaQuery
        ? '66.8px'
        : aMediaQuery
        ? '89.8px'
        : '120px'
    }
  };

  const searchField = {
    '& .MuiInputBase-input': {
      padding: '10px 14px !important'
    },
    '& .MuiFormLabel-root': {
      top: '-7px !important'
    },
    '& .MuiInputBase-root': {
      paddingRight: '0px !important',
      borderRadius: '20px !important',
      transition: 'all 0.4s ease',
      width: vMediaQuery ? '170px' : isFocused ? '100%' : '70px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: isFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: isFocused ? '1px solid rgba(0, 0, 0, 0.23)' : 'none' // Hide the border on hover
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: isFocused ? '1px solid #1976d2' : 'none' // Hide the border when focused
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return {
          color: theme.palette.primary.text,
          backgroundColor: theme.palette.statusClr.open,
          fontFamily: theme.palette.primary.font
        };
      case 'Progress':
        return {
          color: 'white',
          backgroundColor: theme.palette.statusClr.progress,
          fontFamily: theme.palette.primary.font
        };
      case 'Verification':
        return {
          color: 'white',
          backgroundColor: theme.palette.statusClr.verified,
          fontFamily: theme.palette.primary.font
        };
      default:
        return 'default';
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Low':
        return {
          color: theme.palette.primary.text,
          backgroundColor: theme.palette.statusClr.low,
          fontFamily: theme.palette.primary.font
        };
      case 'Medium':
        return {
          color: 'white',
          backgroundColor: theme.palette.statusClr.medium,
          fontFamily: theme.palette.primary.font
        };
      case 'High':
        return {
          color: 'white',
          backgroundColor: theme.palette.statusClr.high,
          fontFamily: theme.palette.primary.font
        };
      case 'Critical':
        return {
          color: 'white',
          backgroundColor: theme.palette.statusClr.critical,
          fontFamily: theme.palette.primary.font
        };
      default:
        return 'default';
    }
  };

  const getFinishedDaysColor = (percentage) => {
    if (percentage < 50) {
      return {
        color: 'white',
        backgroundColor: theme.palette.statusClr.green,
        label: 'Green',
        fontFamily: theme.palette.primary.font
      };
    } else if (percentage < 75) {
      return {
        color: 'white',
        backgroundColor: theme.palette.statusClr.yellow,
        label: 'Yellow',
        fontFamily: theme.palette.primary.font
      };
    } else if (percentage < 90) {
      return {
        color: 'white',
        backgroundColor: theme.palette.statusClr.orange,
        label: 'Orange',
        fontFamily: theme.palette.primary.font
      };
    } else {
      return {
        color: 'white',
        backgroundColor: theme.palette.statusClr.red,
        label: 'Red',
        fontFamily: theme.palette.primary.font
      };
    }
  };
  const users = user?.filterData?.map((u) => u.UId).join(',') || '';

  const fSupervisingTask = async () => {
    const obj = {
      User_id: users
    };
    const result = await supervisingTasksAPI(obj);

    if (result.status === 200) {
      setSupervisingData(result.data);

      // Distinguish unique Status
      const statuses = ['Open', 'Progress', 'Verification'];
      const uniqueStatusFl = statuses.filter((status) =>
        result.data.some(
          (item) =>
            item.T_Status.charAt(0).toUpperCase() + item.T_Status.slice(1).toLowerCase() === status
        )
      );
      setUniqueStatus(uniqueStatusFl);

      const statusCount = result.data.reduce((acc, item) => {
        acc[item.T_Status.charAt(0).toUpperCase() + item.T_Status.slice(1).toLowerCase()] =
          (acc[item.T_Status.charAt(0).toUpperCase() + item.T_Status.slice(1).toLowerCase()] || 0) +
          1;
        return acc;
      }, {});
      setStatusCounts(statusCount);

      // Distinguish unique priorities
      const priorities = ['Low', 'Medium', 'High', 'Critical'];
      const uniquePriority = priorities.filter((priority) =>
        result.data.some((item) => item.T_Priority === priority)
      );
      setUniquePriorities(uniquePriority);

      const priorityCount = result.data.reduce((acc, item) => {
        acc[item.T_Priority] = (acc[item.T_Priority] || 0) + 1;
        return acc;
      }, {});
      setPriorityCounts(priorityCount);

      // Distinguish unique Zone
      const zones = ['Green', 'Yellow', 'Orange', 'Red'];
      const uniqueLabel = zones.filter((label) =>
        result.data.some(
          (item) => getFinishedDaysColor(item.FinishedDaysPercentage).label === label
        )
      );
      setUniqueLabels(uniqueLabel);

      const labelCount = result.data.reduce((acc, item) => {
        const label = getFinishedDaysColor(item.FinishedDaysPercentage).label;
        acc[label] = (acc[label] || 0) + 1;
        return acc;
      }, {});

      setZoneCounts(labelCount);
    }
  };

  const filterTaskStatus = (tasks) => {
    return tasks.filter((task) => {
      const statusMatch =
        selectedStatus.length === 0 ||
        selectedStatus.includes(
          task.T_Status.charAt(0).toUpperCase() + task.T_Status.slice(1).toLowerCase()
        );
      return statusMatch;
    });
  };
  const filterTaskPriority = (tasks) => {
    return tasks.filter((task) => {
      const priorityMatch =
        selectedPriorities.length === 0 || selectedPriorities.includes(task.T_Priority);
      return priorityMatch;
    });
  };
  const filterTaskLabel = (tasks) => {
    return tasks.filter((task) => {
      const zoneLabel = getFinishedDaysColor(task.FinishedDaysPercentage).label;
      const labelMatch = selectedLabels.length === 0 || selectedLabels.includes(zoneLabel);
      return labelMatch;
    });
  };
  const filterTaskUser = (tasks) => {
    return tasks.filter((task) => {
      const searchTerm = filter.toLowerCase();
      const searchMatch =
        task?.T_Desc?.toLowerCase().includes(searchTerm) ||
        task?.Assignee?.toLowerCase().includes(searchTerm);
      return searchMatch;
    });
  };

  // Separating the filtering logic
  const getFilteredTasks = () => {
    if (
      !filter &&
      selectedStatus.length === 0 &&
      selectedPriorities.length === 0 &&
      selectedLabels.length === 0
    ) {
      return supervisingData; // Return full dataset if no filter is applied
    }

    const data1 = filterTaskStatus(supervisingData);
    const data2 = filterTaskPriority(data1);
    const data3 = filterTaskLabel(data2);
    const data4 = filterTaskUser(data3);

    return data4;
  };

  // In your component, replace filteredTasks with this function
  const filteredTasks = getFilteredTasks();

  const handleTaskClick = (task) => {
    setSelectedTask(task);

    if (task?.T_Status === 'Verification') {
      setDetailModalOpen(true); // Open the SupervisingDetailTask modal

      setTimeout(() => {
        document.getElementById('naration')?.focus();
      }, 500);
    } else {
      setDrawerOpen(true); // Open the UpdateFollowUpDrawer

      setTimeout(() => {
        document.getElementById('activity')?.focus();
      }, 500);
    }
  };

  const handleRemainderClick = (task, e) => {
    e.stopPropagation(); // Prevent triggering other handlers
    setSelectedTask(task);
    if (task?.T_Status !== 'Verification') {
      setRemainderModal(true);
    }
  };

  const handleDetailModalClose = () => {
    if (isDrawerDataChange) {
      fSupervisingTask();
      setIsDrawerDataChange(true);
    }
    setDetailModalOpen(false);
  };

  const handleDrawerClose = () => {
    if (isDrawerDataChange) {
      fSupervisingTask();
      setIsDrawerDataChange(true);
    }
    setDrawerOpen(false);
    setIsDrawerDataChange(false);
  };

  const handleRemainderClose = () => {
    if (isDrawerDataChange) {
      fSupervisingTask();
      setIsDrawerDataChange(true);
    }
    setRemainderModal(false);
  };

  const getZoneChipStyle = (label) => {
    const chipStyle = {
      Green: { backgroundColor: '#00c875', color: 'white' },
      Yellow: { backgroundColor: '#fecd02', color: 'white' },
      Orange: { backgroundColor: '#fdab3d', color: 'white' },
      Red: { backgroundColor: '#df2f4a', color: 'white' }
    };

    return selectedLabels.includes(label)
      ? {
          ...chipStyle[label],
          height: '26px',
          fontSize: '12px'
        }
      : chipStyle[label];
  };

  const handlePriorityChipClick = (priority) => {
    if (selectedPriorities.includes(priority)) {
      setSelectedPriorities(selectedPriorities.filter((p) => p !== priority));
    } else {
      setSelectedPriorities([...selectedPriorities, priority]);
    }
  };

  const handleLabelChipClick = (label) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((l) => l !== label));
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  const handleStatusChipClick = (status) => {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter((s) => s !== status));
    } else {
      setSelectedStatus([...selectedStatus, status]);
    }
  };

  // Calculating dynamic width based on chip count
  const getDynamicWidth = (chipCount) => {
    const minWidth = uMediaQuery ? 0 : 100; // minimum width
    const widthPerChip = uMediaQuery ? 75 : 100; // width to add per chip
    return Math.min(minWidth + chipCount * widthPerChip, 600); // max width can be 600px
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 12,
      maxWidth: 'none'
    }
  }));

  // Handle Excel Export
  const handleExportExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(supervisingData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Tasks');
    XLSX.writeFile(workBook, 'supervising_data.xlsx');
  };

  useEffect(() => {
    fSupervisingTask();
  }, [apiCallCounts?.mySupervisingRefreshCount, user?.filterData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="search-field">
        <CmpTypographyField xMediaQuery={xMediaQuery} xText={'My Supervising'} />
        <div className="exportDiv">
          <TextField
            sx={searchField}
            id="filter"
            variant="outlined"
            margin="normal"
            placeholder={isFocused ? 'Search...' : ''}
            value={filter}
            onFocus={() => {
              setIsFocused(true);
            }}
            onChange={(e) => setFilter(e.target.value)}
            onBlur={() => {
              if (!filter) {
                setIsFocused(false);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsFocused(true);
                    }}
                    sx={{
                      border: isFocused ? 'none' : '1px solid #dbd1d1',
                      padding: '6px !important',
                      backgroundColor: isFocused ? 'transparent' : 'white'
                    }}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box className="exportBox">
            <IconButton onClick={handleExportExcel}>
              <FileDownloadOutlinedIcon />
            </IconButton>
            <Typography variant="caption" sx={{ mt: -1 }}>
              Export
            </Typography>
          </Box>
        </div>
      </div>
      <Grid container columnSpacing={0} sx={{ p: xMediaQuery ? '' : 1, pb: xMediaQuery ? 1 : '' }}>
        <Grid
          item
          xs={vMediaQuery ? 12 : 'auto'}
          className={uniqueStatus.length > 0 ? 'chip' : ''}
          sx={{ width: getDynamicWidth(uniqueStatus.length) }}>
          {/* Status Filter Chips */}
          <Box sx={{ mb: xMediaQuery ? 1 : vMediaQuery ? 1 : '', mt: xMediaQuery ? 1 : '' }}>
            <Grid container spacing={0}>
              {uniqueStatus.map((status, index) => (
                <Grid item key={index} >
                  <Badge
                    sx={badgeStyle}
                    badgeContent={statusCounts[status] || 0}
                    color={selectedStatus.includes(status) ? 'secondary' : 'primary'}
                    overlap="circular">
                    <Chip
                      sx={chipsStyle}
                      label={status}
                      style={getStatusColor(status)}
                      onClick={() => handleStatusChipClick(status)}
                    />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={vMediaQuery ? 12 : 'auto'}
          className={uniquePriorities.length > 0 ? 'chip' : ''}
          sx={{ width: getDynamicWidth(uniquePriorities.length) }}>
          {/* Priority Filter Chips */}
          <Box sx={{ mb: xMediaQuery ? 1 : vMediaQuery ? 1 : '' }}>
            <Grid container spacing={0}>
              {uniquePriorities.map((priority, index) => (
                <Grid item key={index} >
                  <Badge
                    sx={badgeStyle}
                    badgeContent={priorityCounts[priority] || 0}
                    color={selectedPriorities.includes(priority) ? 'secondary' : 'primary'}
                    overlap="circular">
                    <Chip
                      sx={chipsStyle}
                      label={priority}
                      style={getPriorityColor(priority)}
                      onClick={() => handlePriorityChipClick(priority)}
                    />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        {/* Zone Filter Chips */}
        <Grid
          item
          xs={vMediaQuery ? 12 : 'auto'}
          className="zone-chip"
          sx={{ width: getDynamicWidth(uniqueLabels.length) }}>
          <Box>
            <Grid container spacing={0}>
              {uniqueLabels.map((label, index) => (
                <Grid item key={index}>
                  <Badge
                    sx={badgeStyle}
                    badgeContent={zoneCounts[label] || 0}
                    color={selectedLabels.includes(label) ? 'secondary' : 'primary'}
                    overlap="circular">
                    <Chip
                      key={index}
                      sx={chipsStyle}
                      label={label}
                      style={getZoneChipStyle(label)}
                      onClick={() => handleLabelChipClick(label)}
                    />
                  </Badge>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <TableContainer className="table" component={Paper}>
        <Table
          size={isMobile ? 'small' : 'medium'}
          sx={{
            '& .MuiTableCell-root': {
              borderRight: theme.palette.tblColBorder,
              textAlign: 'left'
            }
          }}
          stickyHeader>
          <TableHead>
            <TableRow className="table-head">
              {/* <TableCell>Task ID</TableCell> */}
              {/* <TableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: theme.palette.primary.main
                }}>
                Title
              </TableCell> */}
              <TableCell
                sx={{
                  position: 'sticky',
                  left: bMediaQuery ? 0 : '',
                  zIndex: bMediaQuery ? 3 : '',
                  backgroundColor: bMediaQuery ? '#eef1fd' : ''
                }}>
                Description
              </TableCell>
              <TableCell>Assignee</TableCell>
              <TableCell>Remainder</TableCell>
              <TableCell>Activity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Recent Status</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Next Follow-Up</TableCell>
              <TableCell>Last Follow-Up</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Contact No</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Supervisor</TableCell>
              <TableCell>Team Lead</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Inform Customer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks?.map((row, index) => {
              const zoneColor = getFinishedDaysColor(row.FinishedDaysPercentage);
              return (
                <TableRow
                  className="table-body"
                  key={index}
                  sx={{
                    color: `${row.Modified === 1 ? '#010000' : ''} !important`,
                    '& .MuiTableCell-root': {
                      fontWeight: `${row.Modified === 1 ? 600 : ''} !important`
                    },
                    '&:hover': {
                      backgroundColor: `${row.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`,
                    }
                  }}
                  onClick={() => handleTaskClick(row)}>
                  {/* <TableCell>{row.T_ID}</TableCell> */}
                  {/* <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: '#fff',
                      zIndex: 3
                    }}>
                    {row.T_Title}
                  </TableCell> */}
                  <MyTableCell
                    className="table-descrip"
                    text={row.T_Desc}
                    vMediaQuery={vMediaQuery}
                    filteredTasks={filteredTasks}
                  />
                  <TableCell>{row.Assignee}</TableCell>
                  <TableCell onClick={(e) => handleRemainderClick(row, e)}>
                    <div className="zone-chip">
                      {row.T_Reminder === 0 ? (
                        <>-</>
                      ) : (
                        <Badge
                          badgeContent={row.T_Reminder}
                          color="primary"
                          overlap="circular"></Badge>
                      )}
                    </div>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {row.T_Status === 'Verification' ? (
                      <>-</>
                    ) : (
                      <Badge
                        badgeContent={row.FollowUpCount}
                        color="primary"
                        overlap="circular"
                        sx={{
                          '& .MuiBadge-badge': {
                            border: `1px solid ${theme.palette.primary.text} !important`
                          }
                        }}>
                        {row.FollowUpCount === 0 ? (
                          <MapsUgcOutlinedIcon
                            sx={{
                              '&. MuiSvgIcon-root': {
                                fill: `#323338 !important`,
                                fontWeight: 200,
                                fontFamily: theme.palette.primary.font
                              }
                            }}
                          />
                        ) : (
                          <MapsUgcOutlinedIcon
                            sx={{
                              '& path:nth-of-type(2)': {
                                visibility: 'hidden'
                              }
                            }}
                          />
                        )}
                      </Badge>
                    )}
                  </TableCell>
                  <TableCell
                    sx={getStatusColor(
                      row.T_Status.charAt(0).toUpperCase() + row.T_Status.slice(1).toLowerCase()
                    )}>
                    {row.T_Status.charAt(0).toUpperCase() + row.T_Status.slice(1).toLowerCase()}
                  </TableCell>
                  <TableCell
                    className="table-status"
                    sx={{
                      '&:hover': {
                        backgroundColor: `${row.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`
                      }
                    }}>
                    {/* {row.T_Desc} */}
                    <LightTooltip
                      title={row.RecentStatus || ''}
                      placement="bottom-start"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 400 }}>
                      <span>{row.RecentStatus}</span>
                    </LightTooltip>
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row.T_DueDate ? moment(row.T_DueDate).format('DD/MM/YYYY') : ''}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row.nextFollowUp ? moment(row.nextFollowUp).format('DD/MM/YYYY') : ''}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row.LastFollowUp ? moment(row.LastFollowUp).format('DD/MM/YYYY') : ''}
                  </TableCell>
                  <TableCell sx={zoneColor}>{row.FinishedDaysPercentage}% </TableCell>
                  <TableCell style={{ textAlign: 'center' }} sx={getPriorityColor(row.T_Priority)}>
                    {row.T_Priority}
                  </TableCell>
                  <TableCell>{row.CustomerName}</TableCell>
                  <TableCell>{row.T_ContactPerson}</TableCell>
                  <TableCell>{row.T_ContactNo}</TableCell>
                  <TableCell>{row.T_Email}</TableCell>
                  <TableCell>{row.Department}</TableCell>
                  <TableCell>{row.ProjectName}</TableCell>
                  <TableCell>{row.Supervisor}</TableCell>
                  <TableCell>{row.TeamLead}</TableCell>
                  <TableCell>{row.LocName}</TableCell>
                  <TableCell>{row.CreatedBy}</TableCell>
                  <TableCell>{moment(row.CreatedDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{row.T_InformCust === 'Y' ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <UpdateFollowUpDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        selectedTask={selectedTask}
        xMediaQuery={xMediaQuery}
        apiCallCounts={apiCallCounts}
        setIsDrawerDataChange={setIsDrawerDataChange}
        heading={'My supervising'}
      />

      <SupervisingDetailTask
        open={detailModalOpen}
        handleClose={handleDetailModalClose}
        task={selectedTask}
        setSelectedTask={setSelectedTask}
        user={user}
        apiCallCounts={apiCallCounts}
      />

      <RemainderModal
        open={remainderModal}
        handleClose={handleRemainderClose}
        task={selectedTask}
        setSelectedTask={setSelectedTask}
        user={user}
        apiCallCounts={apiCallCounts}
      />
    </Box>
  );
};

export default SupervisingTask;
