import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import theme from '../theme.js';
import { useDispatch, useSelector } from 'react-redux';
import { resetUser } from '../Slice/userSlice.js';
import { useMediaQuery } from '@mui/material';
import FilterModal from './FilterModal.jsx';
import NotificationDrawer from '../commonComponents/NotificationDrawer.jsx';

const Header = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:450px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notifyDrawer, setNotifyDrawerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(resetUser());
  };

  const handleFilterClose = () => {
    setFilterOpen(!filterOpen);
  };

  const handleNotifyDrawer = () => {
    setNotifyDrawerOpen(!notifyDrawer);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const fHome = () => {
    navigate('/home');

    setTimeout(() => {
      handleDrawerToggle();
    }, 400);
  };

  const fMyWork = () => {
    navigate('/myWork');

    setTimeout(() => {
      handleDrawerToggle();
    }, 400);
  };

  const fMyProject = () => {
    navigate('/project');

    setTimeout(() => {
      handleDrawerToggle();
    }, 400);
  };

  const fActivity = () => {
    navigate('/todayActivity'); // Ensure this is the correct path
    handleDrawerToggle();
  };

  const drawerList = (
    <List>
      <ListItem onClick={fHome}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem onClick={fMyWork}>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText primary="My Work" />
      </ListItem>
      <ListItem onClick={fMyProject}>
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="Project" />
      </ListItem>
      <ListItem onClick={fActivity}>
        {' '}
        {/* Ensure this calls fActivity */}
        <ListItemIcon>
          <MarkUnreadChatAltIcon />
        </ListItemIcon>
        <ListItemText primary="Today Activity" />
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="static" sx={{ color: '#fff' }} className="toolbar">
        <Toolbar>
          {isMobile && (
            <IconButton
              sx={{
                '&.MuiIconButton-root': {
                  color: theme.palette.primary.text,
                  padding: isMobile ? '2px' : ''
                }
              }}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            sx={{ flexGrow: 1, fontSize: isMobile ? '15px !important' : '1rem' }}>
            Work Management
          </Typography>
          <Typography
            variant={isMobile ? 'body2' : 'h6'}
            sx={{ flexGrow: 1, mt: isMobile ? '2px' : '', fontSize: isMobile ? '12px' : '18px' }}>
            {user?.userName}
            {user?.userCd === '001' && (
              <IconButton
                className="filterIcon"
                onClick={() => {
                  setFilterOpen(true);
                }}
                sx={{
                  '&.MuiIconButton-root': {
                    color: user?.userCd !== '001' ? '#ffffff5e' : theme.palette.primary.text,
                    marginX: isMobile ? '' : '2px !important',
                    marginBottom: isMobile ? '' : '4px !important',
                    padding: '0px !important'
                  }
                }}>
                <FilterAltOutlinedIcon />
              </IconButton>
            )}
          </Typography>
          <IconButton
            className="notify-count"
            sx={{
              '&.MuiIconButton-root': {
                color: theme.palette.primary.text,
                paddingRight: isMobile ? '16px' : '30px',
                paddingLeft: isMobile ? '0px' : '0px'
              }
            }}
            aria-label="logout"
            onClick={handleNotifyDrawer}>
            <Badge
              overlap="circular"
              badgeContent={user?.unreadNotifications || 0} // Show unread count on Un-Read chip
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {isMobile ? (
            <IconButton
              sx={{
                '&.MuiIconButton-root': {
                  color: theme.palette.primary.text,
                  padding: '0px !important'
                }
              }}
              aria-label="logout"
              onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          ) : (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <FilterModal
        user={user}
        isMobile={isMobile}
        open={filterOpen}
        handleClose={handleFilterClose}
      />

      <NotificationDrawer
        notifyDrawer={notifyDrawer}
        handleNotifyDrawerClose={() => setNotifyDrawerOpen(false)}
        user={user}
        xMediaQuery={isMobile}
      />

      <Drawer
        className="modal"
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '180px',
            height: 'calc(100vh - 64px)',
            top: '64px',
            borderTopRightRadius: '10px',
            backgroundColor: theme.palette.background.page
          },
          '& .MuiModal-backdrop': {
            backgroundColor: 'none !important'
          }
        }}>
        {drawerList}
      </Drawer>
    </>
  );
};

export default Header;
