import { Autocomplete, Box, Button, Checkbox, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import theme from '../theme.js';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredUsers } from '../Slice/userSlice.js';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const FilterModal = ({ open, handleClose, isMobile }) => {
  const users = useSelector((state) => state.user.users);
  const filteredData = useSelector((state) => state.user.filterData);
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = useState(filteredData || []);
  const [unSelectedValues, setUnSelectedValues] = useState(users || []);

  const isAllSelected = selectedValues.length === users.length;

  const handleAutocompleteChange = (event, newValue) => {
    // Check if "Select All" is clicked
    if (newValue.some(option => option === 'Select All')) {
      if (selectedValues.length === unSelectedValues.length) {
        // If all are selected, deselect all
        setSelectedValues([]);
      } else {
        // Select all the options
        setSelectedValues(unSelectedValues);
      }
    } else {
      // If not "Select All", update normally
      setSelectedValues(newValue);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedValues(unSelectedValues); // Select all users
    } else {
      setSelectedValues([]); // Deselect all users
    }
  };

  const handleApply = () => {
    dispatch(setFilteredUsers(selectedValues));
    handleModalClose();
  };

  const handleModalClose = () => {
    setSelectedValues(users); // Reset to initial filtered data
    setUnSelectedValues(users); // Reset to all users
    handleClose();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? 350 : 550,
    bgcolor: '#fff',
    boxShadow: `0px 4px 20px ${theme.palette.primary.main}`,
    borderRadius: 2,
    border: `2px solid ${theme.palette.primary.main}`
  };

  return (
    <Modal open={open} onClose={handleModalClose} className="modal">
      <Box sx={style}>
        <div className="modal-head">
          <Typography variant="subtilte1" sx={{ margin: 0 }}>
            Filter
          </Typography>

          <CloseIcon onClick={handleModalClose} sx={{ cursor: 'pointer' }} />
        </div>
        <Autocomplete
          sx={{ paddingY:isMobile?0: 1, paddingX:isMobile?2: 5 }}
          multiple
          id="checkboxes-tags-demo"
          options={['Select All', ...unSelectedValues]} // Add 'Select All' option at the top
          value={selectedValues}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            option?.Name1 || (option === 'Select All' ? 'Select All' : '')
          }
          onChange={handleAutocompleteChange}
          renderOption={(props, option, { selected }) => {
            const isSelectAllOption = option === 'Select All';
            return (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={isSelectAllOption ? isAllSelected : selected}
                  onChange={isSelectAllOption ? handleSelectAll : undefined} // Handle "Select All" click
                />
                {isSelectAllOption ? 'Select All' : option?.Name1}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Users" placeholder="Select users" />
          )}
        />
        <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
          <Button
            variant="contained"
            sx={{ padding: '2px', textTransform: 'capitalize' }}
            onClick={handleApply}>
            Apply
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default FilterModal;
