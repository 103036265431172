import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../theme.js';
import '../../components/pages.css';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { CmpButton } from '../../commonComponents/Toobox.js';
import CustomerSearchModal from '../CustomerSearchModal.jsx';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fAlertToast } from '../../Utility/Utility.jsx';
import { attributesAPI, updateSalesLeadAPI } from '../../Action.jsx';
import moment from 'moment';

const ProjectDetailsDrawer = ({
  task,
  handleClose,
  setSelectedTask
}) => {
  const aMediaQuery = useMediaQuery('(max-width:1100px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const vMediaQuery = useMediaQuery('(max-width:450px)');
  const xMediaQuery = useMediaQuery('(max-width:1250px)');
  const depts = useSelector((state) => state.user.depts);
  const users = useSelector((state) => state.user.users);
  const projects = useSelector((state) => state.user.project);
  const location = useSelector((state) => state.user.LocationDetail);
  const vLoginData = useSelector((state) => state.user);
  const typTasks = [
    'Payment FollowUp',
    'AMC FollowUp',
    'Software Enhancement',
    'Software Quotation',
    'Bugs Fixing',
    'New Features',
    'Report Development',
    'Support'
  ];
  const [isCustomerSearchModalOpen, setIsCustomerSearchModalOpen] = useState(false);
  const [emiratesArr, setEmiratesArr] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'customerName' && value === '') {
      setSelectedTask((prevState) => ({
        ...prevState,
        customerCode: '',
        contactPerson: '',
        contactNo: '',
        contactEmail: '',
        [name]: value
      }));
    } else {
      setSelectedTask((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date, name) => {
    setSelectedTask((prevState) => ({
      ...prevState,
      [name]: date ? dayjs(date).format('YYYY-MM-DD') : ''
    }));
  };

  const handleSearchCustomer = (e) => {
    if (e.key === 'Enter' || e._reactName === 'onClick') {
      setIsCustomerSearchModalOpen(true);
    }

    setTimeout(() => {
      document.getElementById('accountName')?.focus();
    }, 500);
  };

  const searchCustomerModalClose = () => {
    setIsCustomerSearchModalOpen(false);

    setTimeout(() => {
      document.getElementById('customerName')?.focus();
    }, 500);
  };

  const fAttributes = async () =>{
    const result = await attributesAPI();
    console.log('result6',result);
    if (result.status === 200) {
      setEmiratesArr(result.data);
    }
  }

  const handleAddLead = async () => {
    const payload = {
      L_ID:task.leadID,
      L_Title: task.title,
      L_Desc: task.description,
      L_Date: moment(task.dueDate).format('MM/DD/YYYY'),
      L_AcCode:task.customerCode,
      L_AcName:task.customerName,
      L_Area:task.customeArea,
      L_ContactName: task.contactPerson,
      L_Phone: task.contactPhone,
      T_ContactNo: task.contactNo,
      T_Email: task.contactEmail,
      L_Emirates: task.emirates,
      L_Reference: task.leadReference,
      L_Business_Nature: task.customerBusniness,
      L_Requirements: task.customerRequire,
      L_MarketPrice: task.marketPrice,
      L_FarePrice: task.farePrice,
      L_BottomPrice: task.bottomPrice,
      L_Modules: task.modules,
      L_Dept: task.department,
      L_Assignee: task.assignee,
      L_Supervisor: task.supervisor,
      L_TeamLead: task.teamLead,
      L_Type: task.typofTask,
      L_Project: task.project,
      L_Status: "Active",
      L_UserCd: vLoginData?.userCd,
      T_Loc: task.location,
    };

    console.log('payload', payload);
    
    const result = await updateSalesLeadAPI(payload);
    if (result.status === 200) {
      fAlertToast('SUCCESS', 'Task Sucessfully Added');
      handleClose();
    } else {
      fAlertToast('FAILED', "Task doesn't Added");
    }
  };

  useEffect(()=>{
    fAttributes();
  },[])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div className="add-task">
        <Typography variant="h6" sx={{ margin: 0, color: theme.palette.primary.main }}>
          {task?.leadID === 0 ? 'Add Lead' : 'Lead Detail'}
        </Typography>
        <CloseIcon
          onClick={handleClose}
          sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
        />
      </div>
      <Grid
        className="detail-drawer"
        container
        spacing={xMediaQuery ? 0.4 : aMediaQuery ? 1.4 : 2}
        sx={{ paddingX: xMediaQuery ? '10px' : '24px', pt: '20px' }}>
        {/* Task ID */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Lead ID</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField fullWidth variant="standard" name="leadID" value={task?.leadID || 0} />
        </Grid>

          {/* Title */}
          <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Title</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField fullWidth variant="standard" name="title" value={task?.title || ''}  onChange={handleChange}/>
        </Grid>

          {/* Description */}
          <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Description</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField fullWidth variant="standard" name="description" value={task?.description || ''}  onChange={handleChange}/>
        </Grid>

  {/* Date */}
  <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Due Date</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={task?.dueDate}
                  inputFormat="DD/MM/YYYY"
                  name="dueDate"
                  onChange={(date) => handleDateChange(date, 'dueDate')}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant='standard' id="dueDate" className="duedate" />
                  )}
                />
              </LocalizationProvider>
        </Grid>

   {/* Location */}
   <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Location</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
        <FormControl fullWidth>
                <Select
                  name="location"
                  variant='standard'
                  value={task.location}
                  onChange={handleChange}
                  label="Team Lead">
                  {location?.map((user) => (
                    <MenuItem key={user.Uid} value={user.Uid}>
                      {user.Name1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Grid>

        {/* Title */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Lead Reference</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.leadReference || ''}
            name="leadReference"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Market Price</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            name="marketPrice"
            id="marketPrice"
            type="number"
            value={task?.marketPrice || ''}
            onChange={handleChange}
            InputProps={{
              inputProps: {
                style: {
                  MozAppearance: 'textfield'
                }
              },
              sx: {
                '& input[type=number]': {
                  MozAppearance: 'textfield'
                },
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0
                  }
              }
            }}
          />
        </Grid>

        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Fare Price</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.farePrice || ''}
            name="farePrice"
            id="farePrice"
            type="number"
            onChange={handleChange}
            InputProps={{
              inputProps: {
                style: {
                  MozAppearance: 'textfield'
                }
              },
              sx: {
                '& input[type=number]': {
                  MozAppearance: 'textfield'
                },
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0
                  }
              }
            }}
          />
        </Grid>

        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Bottom Price</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.bottomPrice || ''}
            name="bottomPrice"
            id="bottomPrice"
            type="number"
            onChange={handleChange}
            InputProps={{
              inputProps: {
                style: {
                  MozAppearance: 'textfield'
                }
              },
              sx: {
                '& input[type=number]': {
                  MozAppearance: 'textfield'
                },
                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                  {
                    WebkitAppearance: 'none',
                    margin: 0
                  }
              }
            }}
          />
        </Grid>

        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Job Type</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="typofTask"
              variant="standard"
              value={task?.typofTask}
              onChange={handleChange}
              label="Types of Task">
              {typTasks.map((typ) => (
                <MenuItem key={typ} value={typ}>
                  {typ}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Modules</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.modules || ''}
            name="modules"
            id="modules"
            onChange={handleChange}
          />
        </Grid>

        {/* Customer Code */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Code</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            name="customerCode"
            variant="standard"
            value={task?.customerCode || ''}
            onChange={handleChange}
          />
        </Grid>

        {/* Customer Name */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Name</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            name="customerName"
            id="customerName"
            value={task?.customerName}
            onChange={handleChange}
            onKeyDown={handleSearchCustomer}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchCustomer}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Contact Person */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Person</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.contactPerson || ''}
            name="contactPerson"
            onChange={handleChange}
          />
        </Grid>

        {/* Contact AREA */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Area</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.customeArea || ''}
            name="customeArea"
            onChange={handleChange}
          />
        </Grid>

        {/* Contact No */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact No</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.contactNo || ''}
            name="contactNo"
            onChange={handleChange}
          />
        </Grid>

        {/* Contact Phone */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Phone</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.contactPhone || ''}
            name="contactPhone"
            onChange={handleChange}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Contact Email</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.contactEmail || ''}
            name="contactEmail"
            onChange={handleChange}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Emirates</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
        <FormControl fullWidth>
            <Select
              name="emirates"
              variant="standard"
              value={task?.emirates}
              onChange={handleChange}>
              {emiratesArr.map((emirate) => (
                <MenuItem key={emirate.Uid} value={emirate.Uid}>
                  {emirate.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Department */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Department</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="department"
              variant="standard"
              value={task?.department}
              onChange={handleChange}
              label="Department">
              {depts.map((dept) => (
                <MenuItem key={dept.UId} value={dept.UId}>
                  {dept.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Project Name */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Project</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="project"
              variant="standard"
              value={task?.project}
              onChange={handleChange}
              label="Project">
              {projects.map((project) => (
                <MenuItem key={project.UId} value={project.UId}>
                  {project.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Assignee */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Assignee</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="assignee"
              variant="standard"
              value={task?.assignee}
              onChange={handleChange}
              label="Assignee">
              {users.map((user) => (
                <MenuItem key={user.UId} value={user.UId}>
                  {user.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Supervisor */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Supervisor</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="supervisor"
              variant="standard"
              value={task?.supervisor}
              onChange={handleChange}
              label="Supervisor">
              {users.map((user) => (
                <MenuItem key={user.UId} value={user.UId}>
                  {user.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Team Lead */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Team Lead</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <FormControl fullWidth>
            <Select
              name="teamLead"
              variant="standard"
              value={task?.teamLead}
              onChange={handleChange}
              label="Team Lead">
              {users.map((user) => (
                <MenuItem key={user.UId} value={user.UId}>
                  {user.Name1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* customerRequire */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Busniness</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.customerBusniness || ''}
            name="customerBusniness"
            onChange={handleChange}
          />
        </Grid>

        {/* customerRequire */}
        <Grid item xs={3.2} sm={3.2} md={3.2}>
          <Typography variant="body2">Customer Requirements</Typography>
        </Grid>
        <Grid item xs={0.4} sm={0.5} md={0.5}>
          <Typography variant="body2">:</Typography>
        </Grid>
        <Grid item xs={8.4} sm={8.3} md={8.3}>
          <TextField
            fullWidth
            variant="standard"
            value={task?.customerRequire || ''}
            name="customerRequire"
            onChange={handleChange}
          />
        </Grid>
        {task?.leadID === 0 && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <CmpButton
              xbtnName="Save"
              xVariant="contained"
               xOnClick={handleAddLead}
            />
          </Grid>
        )}
      </Grid>

      <CustomerSearchModal
        open={isCustomerSearchModalOpen}
        handleClose={searchCustomerModalClose}
        handleSearch={handleSearchCustomer}
        addCustomerName={task?.customerName}
        setFormData={setSelectedTask}
        vMediaQuery={vMediaQuery}
      />
    </Box>
  );
};

export default ProjectDetailsDrawer;
