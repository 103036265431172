import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Drawer,
  IconButton,
  Chip,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { fAlertToast } from '../Utility/Utility';
import { useDispatch, useSelector } from 'react-redux';
import '../components/pages.css';
import { getTaskActivityAPI, insertTaskActivityAPI } from '../Action';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { CmpButton } from '../commonComponents/Toobox';
import theme from '../theme.js';
import { setRefeshApiCall } from '../Slice/userSlice.js';

const UpdateFollowUpDrawer = ({
  drawerOpen,
  handleDrawerClose,
  selectedTask,
  xMediaQuery,
  setIsDrawerDataChange,
  heading,
  apiCallCounts
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch(); // Initialize dispatch
  const [activityLog, setActivityLog] = useState('');
  const [activityDate, setActivityDate] = useState('');
  const [activityType, setActivityType] = useState('Follow-Up');
  const [apiData, setApiData] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]); // State to store unique activity types
  const [selectedType, setSelectedType] = useState(''); // State to store selected filter type
  const [usernames, setUsernames] = useState([]); // State to store unique usernames
  const [selectedUsernames, setSelectedUsernames] = useState([]); // State to store selected usernames for filtering
  const [userCounts, setUserCounts] = useState([]); // State to store selected usernames for filtering
  const [typeCounts, setTypeCounts] = useState([]); // State to store selected usernames for filtering

  useEffect(() => {
    if (drawerOpen) {
      setActivityDate(dayjs().add(1, 'day'));
    }
  }, [drawerOpen]);

  const handleAddActivity = async () => {
    const payload = {
      A_T_ID: selectedTask?.T_ID,
      A_T_Type: activityType,
      A_T_Desc: activityLog,
      A_T_EnBy: user?.userCd,
      A_T_NextFollowup: dayjs(activityDate).format('MM/DD/YYYY')
    };

    const response = await insertTaskActivityAPI(payload);
    if (response.status === 200) {
      fAlertToast('SUCCESS', 'Task Activity inserted successfully');

      fetchTaskActivities();
      if (heading === 'My Work') {
        if (activityType === 'Completed') {
          // Increment myWorkRefreshCount and mySupervisingRefreshCount
          dispatch(
            setRefeshApiCall({
              ...user.refreshApiCall,
              myWorkRefreshCount: apiCallCounts?.myWorkRefreshCount + 1,
              mySupervisingRefreshCount: apiCallCounts?.mySupervisingRefreshCount + 1
            })
          );
        } else {
          // Increment only myWorkRefreshCount
          dispatch(
            setRefeshApiCall({
              ...user.refreshApiCall,
              myWorkRefreshCount: apiCallCounts?.myWorkRefreshCount + 1
            })
          );
        }
      } else {
        dispatch(
          setRefeshApiCall({
            ...user.refreshApiCall,
            myWorkRefreshCount: apiCallCounts?.myWorkRefreshCount + 1,
            mySupervisingRefreshCount: apiCallCounts?.mySupervisingRefreshCount + 1
          })
        );
      }

      setActivityLog('');
      setActivityDate(dayjs().add(1, 'day')); // Reset to tomorrow's date
      handleDrawerClose();
      setIsDrawerDataChange(true);
    } else {
      fAlertToast('FAILED', `'Task Activity doesn't inserted'`);
    }
  };

  const fetchTaskActivities = async () => {
    const payload = {
      A_T_ID: selectedTask?.T_ID
    };
    const response = await getTaskActivityAPI(payload);

    if (response.status === 200) {
      setApiData(response?.data);

      // Distinguish unique activity types
      const uniqueTypes = [...new Set(response?.data?.map((item) => item.A_T_Type))];
      setActivityTypes(uniqueTypes); // Set the unique types to local state

      // Distinguish unique usernames
      const uniqueUsernames = [
        ...new Set(response?.data?.map((item) => item.A_T_EnByName).filter(Boolean))
      ];
      setUsernames(uniqueUsernames); // Set the unique usernames to local state

      const typeCount = response?.data?.reduce((acc, item) => {
        acc[item.A_T_Type] = (acc[item.A_T_Type] || 0) + 1;
        return acc;
      }, {});
      setTypeCounts(typeCount);

      const userCount = response?.data?.reduce((acc, item) => {
        acc[item.A_T_EnByName] = (acc[item.A_T_EnByName] || 0) + 1;
        return acc;
      }, {});
      setUserCounts(userCount);
    } else {
      fAlertToast('FAILED', "Couldn't fetch task activities");
    }
  };

  const handleChipClick = (type) => {
    if (selectedType.includes(type)) {
      setSelectedType(selectedType.filter((t) => t !== type));
    } else {
      setSelectedType([...selectedType, type]);
    }
  };

  const handleUsernameChipClick = (username) => {
    if (selectedUsernames.includes(username)) {
      setSelectedUsernames(selectedUsernames.filter((u) => u !== username));
    } else {
      setSelectedUsernames([...selectedUsernames, username]);
    }
  };

  const filteredActivities = apiData.filter(
    (activity) =>
      (selectedType.length === 0 || selectedType.includes(activity.A_T_Type)) &&
      (selectedUsernames.length === 0 || selectedUsernames.includes(activity.A_T_EnByName))
  );

  const handleClose = () => {
    handleDrawerClose();
    setActivityLog('');
    setActivityType('Follow-Up');
    setActivityDate(dayjs().add(1, 'day'));
    setSelectedType([]);
    setSelectedUsernames([]);
  };

  useEffect(() => {
    if (drawerOpen && selectedTask) {
      fetchTaskActivities();
    }
  }, [drawerOpen, selectedTask]);

  return (
    <Drawer
      className="modal"
      anchor="right"
      open={drawerOpen}
      onClose={handleClose}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width: xMediaQuery ? 300 : 500,
          paddingX: xMediaQuery ? 1 : '',
          borderTopLeftRadius: '8px',
          borderRight: 'none !important',
          borderBottom: 'none !important',
          border: theme.palette.shadow.border,
          boxShadow: theme.palette.shadow.main,
          backgroundColor: theme.palette.background.drawer,
          overflowX: 'hidden',
          top: 'auto',
          bottom: 0,
          height: `calc(100vh - 64px) !important`
        }
      }}>
      <div>
        <IconButton onClick={handleClose} sx={{ cursor: 'pointer', color: 'GrayText' }}>
          <CloseIcon />
        </IconButton>
      </div>
      <Box sx={{ paddingX: xMediaQuery ? '0px' : 3 }} className="task-detail">
        <Typography variant="h6" color="primary" sx={{fontSize:xMediaQuery?'16px':'20px'}}>
          {selectedTask?.T_Title}
        </Typography>
        <Typography variant="body1" className="card-descript">
          {selectedTask?.T_Desc}
        </Typography>
        <TextField
          label="Update Activity"
          id="activity"
          variant="outlined"
          fullWidth
          value={activityLog}
          onChange={(e) => setActivityLog(e.target.value)}
          sx={{ '&.MuiTextField-root': { marginY: '16px' } }}
          multiline
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Next followUp Date"
              name="followupDate"
              value={activityDate}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => setActivityDate(date, 'followupDate')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="duedate"
                  sx={{ '&.MuiTextField-root': { marginRight: 1 } }}
                />
              )}
            />
          </LocalizationProvider>

          <FormControl fullWidth sx={{ '&.MuiFormControl-root': { marginRight: 1 } }}>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={activityType}
              onChange={(e) => setActivityType(e.target.value)}
              label="Type">
              <MenuItem value="Follow-Up">Follow-Up</MenuItem>
              {heading === 'My Work' ? <MenuItem value="Completed">Completed</MenuItem> : <></>}
            </Select>
          </FormControl>

          <CmpButton xbtnName="Save" xOnClick={handleAddActivity} />
        </div>

        {/* Chips for Activity Types */}
        <Box sx={{ marginRight: 4, marginY: 1 }}>
          Types
          <Grid container spacing={1} className="badgeClass">
            {activityTypes.map((type, index) => (
              <Grid
                item
                key={index}
                sx={{ '&.MuiGrid-item': { paddingTop: '4px !important' } }}>
                {type === 'Re-Open' ? (
                  <Badge badgeContent={typeCounts[type] || 0} overlap="circular">
                    <Chip
                      label={type}
                      color={selectedType.includes(type) ? 'secondary' : 'primary'}
                      onClick={() => handleChipClick(type)}
                    />
                  </Badge>
                ) : type === 'Follow-Up' || type === 'Reminder' ? (
                  <Badge
                    badgeContent={type === 'Follow-Up'? selectedTask?.FollowUpCount: selectedTask.T_Reminder}
                    overlap="circular">
                    <Chip
                      label={type}
                      color={selectedType.includes(type) ? 'secondary' : 'primary'}
                      onClick={() => handleChipClick(type)}
                    />
                  </Badge>
                ) : (
                  <Chip
                    label={type}
                    color={selectedType.includes(type) ? 'secondary' : 'primary'}
                    onClick={() => handleChipClick(type)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Chips for Usernames */}
        <Box sx={{ marginTop: 2, marginY: 1 }}>
          <Typography variant="body1">Users</Typography>
          <Grid container spacing={1} className="badgeClass">
            {usernames.map((username, index) => (
              <Grid
                item
                key={index}
                sx={{ '&.MuiGrid-item': { paddingTop: '4px !important' } }}>
                <Badge
                  badgeContent={userCounts[username] || 0} // Display the unique user count
                  overlap="circular">
                  <Chip
                    label={username}
                    color={selectedUsernames.includes(username) ? 'secondary' : 'primary'}
                    onClick={() => handleUsernameChipClick(username)}
                  />
                </Badge>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* New Div Container to Display Activities */}
        {filteredActivities.map((activity, index) => (
          <Card
            className="card"
            key={index}
            sx={{
              mb: 1,
              backgroundColor: 'background.drawer',
              border: ` ${theme.palette.shadow.borderClr} !important`,
              borderRadius: '10px'
            }}>
            <CardContent>
              <Box sx={{ marginBottom: 2 }}>
                <Typography className="descript" variant="body2">
                  {activity.A_T_Desc}
                  {activity.A_T_NextFollowup && (
                    <>
                      , Next Follow-up @{' '}
                      <span style={{ color: theme.palette.statusClr.red }}>
                        {moment(activity.A_T_NextFollowup).format('DD/MM/YYYY')}
                      </span>
                    </>
                  )}
                </Typography>

                <Typography
                  className="descriptDate"
                  variant="body2"
                  color={theme.palette.statusClr.high}>
                  <strong> {activity.A_T_EnByName}</strong>
                </Typography>
                <div className="aligntext">
                  <Typography className="descriptDate" variant="body2">
                    {moment(activity.A_T_Datetime).format('DD/MM/YYYY')}{' '}
                    {moment(activity.A_T_Datetime).format('hh:mm A')}
                  </Typography>
                  <Typography className="type" color={theme.palette.statusClr.low}>
                    {activity.A_T_Type}
                  </Typography>
                </div>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Drawer>
  );
};

export default UpdateFollowUpDrawer;
