import React from 'react';
import { Fade, TableCell, Tooltip, styled } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    margin:  '1px 16px !important',
    padding: '4px 8px',
    maxWidth: 'none',
    fontSize:12
  },
  [`& .MuiTooltip-popper`]: {
    margin: '0 !important',
  },
});

const MyTableCell = ({ text, vMediaQuery,className,filteredTasks }) => {
  return (
    <CustomTooltip
      title={text}
      placement="bottom-start"
      TransitionComponent={Fade}
     TransitionProps={{ timeout: 600 }}
    >
      <TableCell
       className={className}
        sx={{
          position: vMediaQuery ? 'static' : 'sticky',
          '&:hover': {
            backgroundColor:  `${filteredTasks?.Modified === 1 ? '#eef1fd45' : '#c4c4c40f'}`,
          },
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {text}
        </div>
      </TableCell>
    </CustomTooltip>
  );
};

export default MyTableCell;
