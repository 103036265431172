import React, { useState } from 'react';
import Marquee from 'react-marquee-slider';
import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import theme from '../theme.js';
import { useSelector } from 'react-redux';
import UpdateFollowUpDrawer from '../components/UpdateFollowUpDrawer.js';


const FlashMessageContainer = styled.div`
  background-color: ${theme.palette.background.default};
  border: ${theme.palette.shadow.flash};
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
`;

const ScrollingFlashMessage = ({ apiCallCounts }) => {
  const xMediaQuery = useMediaQuery('(max-width:450px)');
  const redZonesData = useSelector((state) => state.user.redZones);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [marqueeSpeed, setMarqueeSpeed] = useState(3);

  // Function to handle mouse hover and pause scrolling
  const handleMouseEnter = () => {
    setMarqueeSpeed(0); // Stop the marquee
  };

  const handleMouseLeave = () => {
    setMarqueeSpeed(3); // Resume the marquee
  };

  const handleFlashClick = (msg) => {
    setSelectedTask(msg);
    setDrawerOpen(true);
  };

  return (
    <>
      {redZonesData?.length > 0 && (
        <FlashMessageContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Marquee velocity={marqueeSpeed} resetAfterTries={2} onFinish={() => {}}>
            {redZonesData?.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '30px' // Add spacing between messages,
                }}
                onClick={() => handleFlashClick(msg)}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.statusClr.red,
                    marginRight: '10px',
                    fontWeight: 'bold',
                    fontFamily: theme.palette.primary.font
                  }}>
                  {msg.FinishedDaysPercentage}% -
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.secondary.text,
                    fontWeight: 400,
                    fontFamily: theme.palette.primary.font
                  }}>
                  {msg.T_Desc}
                </Typography>
              </Box>
            ))}
          </Marquee>
        </FlashMessageContainer>
      )}

      <UpdateFollowUpDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={() => setDrawerOpen(false)}
        selectedTask={selectedTask}
        xMediaQuery={xMediaQuery}
        setIsDrawerDataChange={() => {}}
        heading={'My Work'}
        apiCallCounts={apiCallCounts}
      />
    </>
  );
};

export default ScrollingFlashMessage;
