import axios from 'axios';
import { fAlertToast } from '../Utility/Utility';
import { io } from 'socket.io-client';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  responseType: 'json',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export const httpRequest = async (axiosData) => {
  const loader = document.getElementById('loader');
  try {
    loader.style.display = 'flex';
    const res = await axiosInstance.request(axiosData);

    loader.style.display = 'none';
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    loader.style.display = 'none';
    console.error('Response data:', error?.response?.data); // Log the response data
    console.error('Response status:', error?.response?.status); // Log the response status
    console.error('Response headers:', error?.response?.headers);
    console.error('Request data:', error?.request); // Log the request data
    console.error('Error message:', error?.message); // Log the error message

    fAlertToast('FAILED', error?.response?.data || error?.message || 'error');

    throw error;
  }
};

// const SOCKET_SERVER_URL = 'https://5549-2405-201-e065-82a-5db3-d2af-626a-87a6.ngrok-free.app/';

// Connect to the Socket.IO server
export const newSocket = io(process.env.REACT_APP_BASE_URL, {
  reconnectionAttempts: 5, // Limit number of reconnection attempts
  reconnectionDelay: 1000, // Delay between reconnection attempts (in ms)
  reconnectionDelayMax: 5000, // Max delay between reconnection attempts
  timeout: 20000, // Connection timeout (in ms)
  transports: ['websocket', 'polling'] // Ensures compatibility with different environments
});
